// import { lazy } from "react";

import BreakCategory from "app/pages/Setting/BreakCategory/BreakCategory";
import IssueCategory from "app/pages/Setting/IssueCategory";
import Knowledgebase from "app/pages/Setting/KnowledgeBase/knowledgebase";
import RootCauseandResolution from "app/pages/Setting/RootCauseAndResolution";
import StatusTypes from "app/pages/Setting/StatusTypes";
import Template from "app/pages/Setting/Template/Template";
import TicketTypes from "app/pages/Setting/TicketTypes";
import UserSetting from "app/pages/Setting/UserSetting";

const setupRoutes = [
  {
    path: "/app/setup/issue-category",
    shortPath: "/setup/issue-category",
    name: "Issue Category",
    component: IssueCategory,
    icon: "mdi mdi-shape",
  },
  {
    path: "/app/setup/template",
    shortPath: "/setup/template",
    name: "Setup Template",
    component: Template,
    icon: "mdi mdi-email",
  },
  {
    path: "/app/setup/break-category",
    shortPath: "/setup/break-cateory",
    name: "Break Category",
    component: BreakCategory,
    icon: "mdi mdi-clock",
  },
  // {
  //   path: "/app/setup/root-cause-and-resolution",
  //   shortPath: "/setup/root-cause-and-resolution",
  //   name: "Root Cause And Resolution",
  //   component: RootCauseandResolution,
  //   icon: "mdi mdi-shape",
  // },

  {
    path: "/app/setup/users",
    shortPath: "/setup/users",
    name: "Users",
    component: UserSetting,
    icon: "mdi mdi-account-circle",
  },
  {
    path: "/app/setup/status-types",
    shortPath: "/setup/status-types",
    name: "Status Types",
    component: StatusTypes,
    icon: "mdi mdi-alpha-s-box",
  },
  {
    path: "/app/setup/ticket-types",
    shortPath: "/setup/ticket-types",
    name: "Ticket Types",
    component: TicketTypes,
    icon: "mdi mdi-alpha-t-box",
  },
  // {
  //   path: "/app/setup/users",
  //   shortPath: "/setup/users",
  //   name: "Users",
  //   component: UserSetting,
  //   icon: "mdi mdi-account-circle",
  // },
  // {
  //   path: "/app/setup/status-types",
  //   shortPath: "/setup/status-types",
  //   name: "Status Types",
  //   component: StatusTypes,
  //   icon: "mdi mdi-alpha-s-box",
  // },
  // {
  //   path: "/app/setup/ticket-types",
  //   shortPath: "/setup/ticket-types",
  //   name: "Ticket Types",
  //   component: TicketTypes,
  //   icon: "mdi mdi-alpha-t-box",
  // },
  // {
  //   path: "/app/setup/create-user",
  //   shortPath: "/setup/create-user",
  //   name: "User Setup",
  //   component: UserSetting,
  //   icon: "mdi mdi-account-circle",
  // },
  // {
  //   path: "/app/setup/knowledge-base",
  //   shortPath: "/setup/knowledge-base",
  //   name: "knowledge Base",
  //   component: Knowledgebase,
  //   icon: "mdi mdi-information",
  // },
  // {
  //   path: "/app/setup/setup-permissions",
  //   shortPath: "/setup/setup-permissions",
  //   name: "Setup permissions",
  //   component: CreatePermissions,
  //   icon: "mdi mdi-account-multiple-check",
  // },
];

export default setupRoutes;
