import {
  Person,
  Badge,
  PendingActions,
  CheckCircle,
  Grid3x3,
  Phone,
  PlayCircleFilledWhite,
  Visibility,
  Info,
  Email,
} from "@mui/icons-material";
import {
  Modal,
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
  TextField,
  Switch,
  Button,
  FormControlLabel,
  RadioGroup,
  Checkbox,
  Radio,
  Snackbar,
  Alert,
  IconButton,
  Tooltip,
  Autocomplete,
  Grid,
  Divider,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "app/components/Shared/DatePicker/DatePicker.component";
import { instance } from "app/utils/axiosRequest";
import {
  agentAssignTicket,
  agentAssignTicketDepartment,
  agentGetTemplate,
  agentGetallQueue,
  agentGetallUser,
  agentlistenRecording,
  agentlistenVoicemail,
  updateTicketStatus,
} from "app/utils/api-urls/AgentApiUrls";
import dayjs from "dayjs";
import ReactAudioPlayer from "react-audio-player";
import {
  adminAssignTicket,
  adminAssignTicketDepartment,
  adminlistenRecording,
  adminlistenVoicemail,
  adminupdateTicketStatus,
  allQueueList,
  extensionList,
  getEmailTemplate,
} from "app/utils/api-urls/AdminAPiUrls";
import {
  supervisorAssignTicket,
  supervisorAssignTicketDepartment,
  supervisorGetallQueue,
  supervisorGetallUser,
  supervisorgetEmailTemplate,
  supervisorlistenRecording,
  supervisorlistenVoicemail,
  supervisorupdateTicketStatus,
} from "app/utils/api-urls/SupervisorAPiUrls";
import ChatPreviewModal from "app/pages/dashboard/ChatViewModal";
import NotificationModal from "../createNew/NotificationModal";
import { useQuery } from "@tanstack/react-query";
import { API_ENDPOINTS } from "app/utils/api-urls/allUrls";
const FollowUpTIcketModal = ({
  openModal,
  handleCloseFollowUpPage,
  followData,
  refetch,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 850,
    maxHeight: 700,
    overflowY: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 6,
    borderRadius: 2,
  };

  const userRole = localStorage.getItem("role");

  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const [formValue, setFormValue] = useState({
    remarks: "",
    status: "pending",
    sendNotification: false,
  });

  const [showPlayer, setShowPlayer] = useState(false);
  const [audioSrc, setAudioSrc] = useState(null);
  const [selectedRecordingId, setSelectedRecordingId] = useState(null);
  const [loadingAudio, setLoadingAudio] = useState(false);
  const [isChatModalOpen, setChatModalOpen] = useState(false);
  const [currentChat, setCurrentChat] = useState(null);

  const [sendNotification, setSendNotification] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [notificationsMessageList, setNotificationsMessageList] = useState([]);
  const [notificationMesage, setNotificationMessage] = useState("");

  const [assignedTo, setAssignedTo] = useState({
    type: "",
    value: null,
    agentData: [],
    departmentData: [],
  });

  const { data: agentExtensionData } = useQuery(
    ["agent-extension-data"],
    () => instance.get(API_ENDPOINTS.USER.GET_ALL),
    {
      onSuccess: (data) => {
        setAssignedTo((prev) => ({ ...prev, agentData: data?.data?.data }));
      },
    }
  );

  const { data: departmentData } = useQuery(
    ["agent-department-data"],
    () => instance.get(API_ENDPOINTS.QUEUE.GET_ALL),
    {
      onSuccess: (data) => {
        const departmentData = data?.data?.data;

        setAssignedTo((prev) => ({
          ...prev,
          departmentData: departmentData || [],
        }));
      },
    }
  );

  //get email templates
  const initialTemplateType =
    formValue?.status === "closed" ? "SOLVED" : "PENDING";
  const [templateType, setTemplateType] = useState(initialTemplateType);

  useEffect(() => {
    const mappedFollowUpStatus =
      formValue?.status === "closed" ? "SOLVED" : "PENDING";
    setTemplateType(mappedFollowUpStatus);
  }, [formValue?.status]);

  const { data: getTemplate } = useQuery(["get-template", templateType], () => {
    return instance.get(
      `${API_ENDPOINTS.TEMPLATE.GET_ALL}?type=${templateType}`
    );
  });

  useEffect(() => {
    if (getTemplate) {
      const transformedNotifications = getTemplate?.data?.data?.map(
        (template) => ({
          _id: template._id,
          title: template.title,
          message: template.message,
        })
      );
      setNotificationsMessageList(transformedNotifications);
    }
  }, [getTemplate]);

  const scrollableStyle = {
    maxHeight: "200px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "rgba(0,0,0,.05)",
    },
  };
  const lastRemarkRef = useRef(null);
  const remarksListContainerRef = useRef(null);

  useEffect(() => {
    if (openModal && followData?.remarksList?.length > 0) {
      const timer = setTimeout(() => {
        if (lastRemarkRef.current) {
          remarksListContainerRef.current.scrollTop =
            lastRemarkRef.current.offsetTop;
        }
      }, 10);
      return () => clearTimeout(timer);
    }
  }, [openModal, followData?.remarksList]);

  useEffect(() => {
    //to reset src after modal is not on
    if (!openModal) {
      setAudioSrc(null);
      setSelectedRecordingId(null);
      setShowPlayer(false);
    }
  }, [openModal]);

  const handleChange = (e, name) => {
    const value =
      name === "sendNotification" ? e.target.checked : e.target.value;
    // Check if the status is being updated to 'solved'
    if (name === "status" && value === "closed") {
      setAssignedTo({
        ...assignedTo,
        type: "",
        selectedAgent: null,
        selectedDepartment: null,
      });
    }
    setFormValue((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({
      open: false,
      severity: "",
      message: "",
    });
  };

  //api call to listen recordng
  const handleAudioClick = async (recordingId) => {
    try {
      if (selectedRecordingId === recordingId) {
        setSelectedRecordingId(null);
        setAudioSrc(null);
        setShowPlayer(false); // Hide player
      } else {
        const apiUrl = `${API_ENDPOINTS.CALL_LOGS.GET_RECORDING}/${recordingId}`;
        const response = await instance.get(apiUrl, {
          responseType: "arraybuffer",
        });

        const blob = new Blob([response.data], { type: "audio/wav" });
        const audioUrl = URL.createObjectURL(blob);

        setSelectedRecordingId(recordingId);
        setAudioSrc(audioUrl);
        setShowPlayer(true); // Show player
      }
    } catch (error) {
      console.error("Error fetching audio recording:", error);
    }
  };

  const handleVoiceMailAudioClick = async (recordingId) => {
    try {
      setLoadingAudio(true);
      if (selectedRecordingId === recordingId) {
        setSelectedRecordingId(null);
        setAudioSrc(null);
        setShowPlayer(false);
      } else {
        const adminApiURL = `${adminlistenVoicemail.admin_voicemail_recording}/${recordingId}`;
        const agentApiURL = `${agentlistenVoicemail.agent_voicemail_recording}/${recordingId}`;
        const supervisorApiUrl = `${supervisorlistenVoicemail.supervisor_voicemail_recording}/${recordingId}`;
        const apiUrl =
          userRole === "admin"
            ? adminApiURL
            : userRole === "agent"
            ? agentApiURL
            : userRole === "supervisor"
            ? supervisorApiUrl
            : null;

        const response = await instance.get(apiUrl, {
          responseType: "arraybuffer",
        });

        const blob = new Blob([response.data], { type: "audio/wav" });
        const audioUrl = URL.createObjectURL(blob);

        setSelectedRecordingId(recordingId);
        setAudioSrc(audioUrl);
        setShowPlayer(true); // Show player
      }
      setLoadingAudio(false);
    } catch (error) {
      setLoadingAudio(false);
      setSnackBar({
        open: true,
        message: "Error fetching audio recording.",
      });
    }
  };

  const handleAssignedToChange = (event, type) => {
    setAssignedTo({ ...assignedTo, type: event.target.value, value: null });
  };

  const handleAgentSelectionChange = (event, newValue) => {
    setAssignedTo((prev) => ({
      ...prev,
      selectedAgent: newValue ? newValue.extension : null,
    }));
  };

  const handleDepartmentSelectionChange = (event, newValue) => {
    setAssignedTo((prev) => ({
      ...prev,
      selectedDepartment: newValue ? newValue._id : null,
    }));
  };

  const handleAgentCheckboxChange = (event) => {
    setAssignedTo((prev) => ({
      ...prev,
      selectedAgent: event.target.checked ? prev.selectedAgent : null,
      selectedDepartment: event.target.checked ? null : prev.selectedDepartment,
      type: event.target.checked ? "agent" : "",
    }));
  };

  const handleDepartmentCheckboxChange = (event) => {
    setAssignedTo((prev) => ({
      ...prev,
      selectedDepartment: event.target.checked ? prev.selectedDepartment : null,
      selectedAgent: event.target.checked ? null : prev.selectedAgent,
      type: event.target.checked ? "department" : "",
    }));
  };

  const handleSave = async () => {
    await updateTicketData();
    handleCloseFollowUpPage();
    refetch();
  };

  const handleChatPreviewClick = (chat) => {
    setCurrentChat(chat);
    setChatModalOpen(true);
  };

  const handleSelectMessage = (message) => {
    setNotificationMessage(message);
  };

  const handleNotificationChange = (event) => {
    setSendNotification(event.target.checked);
    if (!event.target.checked) {
      setNotificationMessage("");
    } else {
      setIsNotificationModalOpen(true);
    }
  };
  const resetStateAndCloseModal = () => {
    setFormValue({
      remarks: "",
      status: "pending",
      sendNotification: false,
    });
    setAssignedTo({
      type: "",
      value: null,
      agentData: [],
      departmentData: [],
    });
    // Add other state resets as needed
    handleCloseFollowUpPage(); // Close the modal
  };

  const updateTicketData = async () => {
    let payload = {
      remarks: formValue?.remarks,
      status: formValue?.status,
      sendNotification: sendNotification,
      notificationMessage: notificationMesage,
    };

    const apiUrl = `${API_ENDPOINTS.TICKET.SOLVE}/${followData?._id}`;
    try {
      let response = await instance.patch(apiUrl, payload);
      if (response?.status === 200) {
        setSnackBar({
          open: true,
          severity: "success",
          message: "Ticket Updated successfully",
        });
        setFormValue({
          remarks: "",
          status: "pending",
          sendNotification: false,
        });
      }
    } catch (error) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Error occured while creating ticket",
      });
    }
  };

  const updateAssignTicketAgent = async () => {
    let payload = {
      remarks: formValue?.remarks,
      extension: assignedTo?.selectedAgent,
    };
    const apiUrl = `${API_ENDPOINTS.TICKET.ASSIGN}/${followData?._id}`;
    try {
      let response = await instance.patch(apiUrl, payload);
      if (response?.status === 200) {
        setSnackBar({
          open: true,
          severity: "success",
          message: "Ticket Assigned successfully",
        });
        resetStateAndCloseModal();
        refetch();
      }
    } catch (error) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Error occured while assigning ticket",
      });
    }
  };

  const updateAssignTicketDepartment = async () => {
    let payload = {
      remarks: formValue?.remarks,
      departmentId: assignedTo?.selectedDepartment,
    };
    const apiUrl = `${API_ENDPOINTS.TICKET.ASSIGN_DEPARTMENT}/${followData?._id}`;

    try {
      let response = await instance.patch(apiUrl, payload);
      if (response?.status === 200) {
        setSnackBar({
          open: true,
          severity: "success",
          message: "Ticket Assigned successfully",
        });
        resetStateAndCloseModal();
        refetch();
      }
    } catch (error) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Error occured while assigning ticket",
      });
    }
  };

  return (
    <>
      {isNotificationModalOpen && (
        <NotificationModal
          open={isNotificationModalOpen}
          handleClose={() => setIsNotificationModalOpen(false)}
          notifications={notificationsMessageList}
          onSelectMessage={handleSelectMessage}
        />
      )}

      <ChatPreviewModal
        isOpen={isChatModalOpen}
        onClose={() => {
          setChatModalOpen(false);
          setCurrentChat(null);
        }}
        chat={currentChat}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackBar.severity}
          sx={{ width: "100%" }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
      <Modal
        open={openModal}
        onClose={handleCloseFollowUpPage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2" sx={{ marginBottom: 2 }}>
            Ticket Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
                gap="20px"
              >
                <Avatar
                  src={`https:kyc.naasasecurities.com.np/${followData?.clientImage}`}
                  alt={followData?.clientName}
                  sx={{ width: 90, height: 90 }}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  gap="10px"
                >
                  <Box display="flex" alignItems="center" gap="5px">
                    <Person />
                    <Typography variant="span">
                      {followData?.clientName}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap="5px">
                    <Badge />
                    <Typography variant="span">
                      {followData?.clientId}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" gap="5px">
                    <Typography variant="span">KYC Status:</Typography>
                    <Typography variant="span">
                      {followData?.kycStatus}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap="10px"
              >
                <Box display="flex" alignItems="center" gap="5px">
                  <Phone />
                  <Typography variant="span">
                    {followData?.clientPhone}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap="5px">
                  <Email />
                  <Typography variant="span">
                    {followData?.clientEmail}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Card sx={{ marginTop: "1em" }}>
            <CardContent>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                color={
                  followData?.status === "pending"
                    ? "#E7625F"
                    : followData?.status === "open"
                    ? "#303481"
                    : "#81B622"
                }
                marginBottom="10px"
              >
                <Box display="flex" alignItems="center">
                  <Grid3x3 />
                  <Typography variant="span">{followData?.ticketId}</Typography>
                </Box>
                {loadingAudio ? (
                  <CircularProgress />
                ) : showPlayer ? (
                  <ReactAudioPlayer
                    controls
                    src={audioSrc}
                    autoPlay
                    style={{ height: "40px" }}
                  />
                ) : null}
                {followData?.type === "NORMAL" && (
                  <Tooltip
                    arrow
                    placement="top"
                    size="lg"
                    title="Play Call Recording"
                  >
                    <IconButton
                      onClick={() => handleAudioClick(followData?._id)}
                      sx={{ color: "#213555" }}
                      aria-label="play"
                    >
                      <PlayCircleFilledWhite />
                    </IconButton>
                  </Tooltip>
                )}
                {followData?.type === "VOICEMAIL" && (
                  <Tooltip
                    arrow
                    placement="top"
                    size="lg"
                    title="Play Voice Mail Recording"
                  >
                    <IconButton
                      onClick={() =>
                        handleVoiceMailAudioClick(followData?.voicemailLogId)
                      }
                      sx={{ color: "#765827" }}
                      aria-label="play"
                    >
                      <PlayCircleFilledWhite />
                    </IconButton>
                  </Tooltip>
                )}
                {followData?.type === "CHAT" && (
                  <Tooltip title="View Chat">
                    <IconButton
                      size="small"
                      onClick={() => handleChatPreviewClick(followData?.chats)}
                    >
                      <Visibility sx={{ color: "#765827" }} fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              <Box display="flex" flexDirection="column" gap="1rem">
                <Box display="flex" width="100%" justifyContent="space-between">
                  <Box width="45%" display="flex" alignItems="center">
                    <Typography
                      fontSize="16px"
                      variant="span"
                      sx={{ marginRight: "10px", fontWeight: "bold" }}
                    >
                      Issue Category:
                    </Typography>
                    <Typography fontSize="16px" variant="span">
                      {followData?.issue?.name}
                    </Typography>
                  </Box>

                  <Box width="45%" display="flex" alignItems="center">
                    <Typography
                      fontSize="16px"
                      variant="span"
                      sx={{ marginRight: "10px", fontWeight: "bold" }}
                    >
                      Sub Issue Category:
                    </Typography>
                    <Typography fontSize="16px" variant="span">
                      {followData?.sub_issue?.name}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" width="100%" justifyContent="space-between">
                  <Box width="45%" display="flex" alignItems="center">
                    <Typography
                      fontSize="16px"
                      variant="span"
                      sx={{ marginRight: "10px", fontWeight: "bold" }}
                    >
                      Opened By :
                    </Typography>
                    <Typography fontSize="16px" variant="span">
                      {followData?.openedBy}
                    </Typography>
                  </Box>

                  <Box
                    width="45%"
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    gap="10px"
                  >
                    <Typography
                      fontSize="16px"
                      variant="span"
                      sx={{ marginRight: "10px", fontWeight: "bold" }}
                    >
                      Assigned User:
                    </Typography>
                    <Typography fontSize="16px" variant="span">
                      {followData?.assignedTo?.join(", ")}
                    </Typography>
                  </Box>
                </Box>{" "}
                <Box display="flex" width="100%" justifyContent="space-between">
                  <Box width="45%" display="flex" alignItems="center">
                    <Typography
                      fontSize="16px"
                      variant="span"
                      sx={{ marginRight: "10px", fontWeight: "bold" }}
                    >
                      Assigned Department:
                    </Typography>
                    <Typography fontSize="16px" variant="span">
                      {followData?.assignedDepartment
                        ?.map((department) => department.name)
                        .join(", ")}
                    </Typography>
                  </Box>
                </Box>{" "}
              </Box>
            </CardContent>
          </Card>
          {followData?.notificationMessages?.length > 0 && (
            <Card sx={{ marginTop: ".5rem" }}>
              <CardContent>
                <Typography fontWeight="bold">
                  Notification Message :{" "}
                </Typography>

                <Box sx={scrollableStyle}>
                  {followData?.notificationMessages?.map((message, index) => (
                    <Box key={index}>
                      <Typography variant="body2">{message}</Typography>
                      <Divider sx={{ mt: "1rem", mb: "1rem" }} />
                      {index ===
                        followData?.notificationMessages.length - 1 && (
                        <Box display="flex" justifyContent="flex-end">
                          <Typography variant="caption">
                            {followData?.notificationStatus}
                          </Typography>
                          <Divider sx={{ mt: "1rem" }} />
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </CardContent>
            </Card>
          )}
          <Card sx={{ marginTop: ".5rem" }}>
            <CardContent>
              <Typography fontWeight="bold">Remarks : </Typography>

              <Box sx={scrollableStyle} ref={remarksListContainerRef}>
                {followData?.remarksList?.map((remarkItem, index) => (
                  <Box
                    key={index}
                    mb={1}
                    ref={
                      index === followData?.remarksList?.length - 1
                        ? lastRemarkRef
                        : null
                    }
                  >
                    <Typography variant="body1">
                      {remarkItem.remarks}
                    </Typography>
                    <Box display="flex" justifyContent="flex-end">
                      <Typography variant="caption">
                        {remarkItem.updatedBy} (
                        {dayjs(remarkItem.updatedAt).format("YYYY-MM-DD HH:mm")}
                        )
                      </Typography>
                    </Box>
                    <Divider sx={{ mt: "1rem" }} />
                  </Box>
                ))}
              </Box>
            </CardContent>
          </Card>
          {/* //Remarks and all assigned one  */}
          <Box display="flex" marginTop="1rem" alignItems="center">
            <TextField
              variant="outlined"
              name="remarks"
              label="Write Follow-Up Remarks Here"
              size="small"
              value={formValue?.remarks}
              onChange={(e) => handleChange(e, "remarks")}
              multiline
              rows={2}
              fullWidth
              required
            />
          </Box>
          {/* actions button */}
          <Box display="flex" gap="20px" width="100%" margin="1em 0">
            <Card sx={{ width: "100%" }}>
              <CardContent>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="2rem"
                >
                  {/* Radio Buttons for Status */}
                  <Box>
                    <RadioGroup
                      row
                      name="status"
                      value={formValue.status}
                      onChange={(e) => handleChange(e, "status")}
                    >
                      <FormControlLabel
                        value="closed"
                        control={<Radio size="small" />}
                        label="Solved"
                      />
                      <FormControlLabel
                        value="pending"
                        control={<Radio size="small" />}
                        label="Pending"
                      />
                    </RadioGroup>
                  </Box>

                  {/* Assign To Section */}
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap="1.5rem"
                  >
                    <Typography variant="subtitle1">Assign To:</Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={assignedTo.type === "agent"}
                          onChange={handleAgentCheckboxChange}
                          disabled={formValue.status === "closed"}
                        />
                      }
                      label="Agent"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={assignedTo.type === "department"}
                          onChange={handleDepartmentCheckboxChange}
                          disabled={formValue.status === "closed"}
                        />
                      }
                      label="Department"
                    />
                  </Box>
                </Box>

                {/* Additional Options */}
                <Box
                  display="flex"
                  marginTop="1rem"
                  flexDirection="row"
                  gap="1rem"
                  alignItems="center"
                >
                  {/* Autocomplete for Agent/Department Selection */}
                  {assignedTo.type === "agent" && (
                    <Autocomplete
                      options={assignedTo.agentData}
                      getOptionLabel={(option) =>
                        `${option.firstName} (${option.extension})`
                      }
                      value={
                        assignedTo.agentData.find(
                          (agent) =>
                            agent.extension === assignedTo.selectedAgent
                        ) || null
                      }
                      onChange={handleAgentSelectionChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Agent"
                          variant="outlined"
                          size="small"
                        />
                      )}
                      style={{ width: 300 }}
                      getOptionSelected={(option, value) =>
                        option.extension === value.extension
                      }
                    />
                  )}
                  {assignedTo.type === "department" && (
                    <Autocomplete
                      options={assignedTo.departmentData}
                      getOptionLabel={(option) => option.name}
                      value={
                        assignedTo.departmentData.find(
                          (department) =>
                            department._id === assignedTo.selectedDepartment
                        ) || null
                      }
                      onChange={handleDepartmentSelectionChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Department"
                          variant="outlined"
                          size="small"
                        />
                      )}
                      style={{ width: 300 }}
                      getOptionSelected={(option, value) =>
                        option._id === value._id
                      }
                    />
                  )}

                  {/* Notify User Checkbox */}
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sendNotification}
                          onChange={handleNotificationChange}
                        />
                      }
                      label="Notify User"
                    />
                    <Tooltip title="If you select 'Notify User', then providing a message is required.">
                      <Info
                        sx={{
                          marginBottom: "6px",
                          fontSize: "20px",
                          color: "#767676",
                        }}
                      />
                    </Tooltip>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>

          <Box mt={3} display="flex" justifyContent="flex-end" gap="10px">
            {!assignedTo.selectedAgent && !assignedTo.selectedDepartment && (
              <Button
                variant="contained"
                color="primary"
                disabled={formValue?.remarks === ""}
                onClick={handleSave}
              >
                Update Ticket
              </Button>
            )}

            {/* Assign to Agent Button */}
            {assignedTo.type === "agent" && assignedTo.selectedAgent && (
              <Button
                variant="contained"
                color="secondary"
                disabled={formValue?.remarks === ""}
                onClick={updateAssignTicketAgent}
              >
                Assign to Agent
              </Button>
            )}

            {/* Assign to Department Button */}
            {assignedTo.type === "department" && assignedTo.selectedDepartment && (
              <Button
                variant="contained"
                color="secondary"
                onClick={updateAssignTicketDepartment}
                disabled={formValue?.remarks === ""}
              >
                Assign to Department
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default FollowUpTIcketModal;
