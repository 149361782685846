import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AddCircleOutlineOutlined,
  DeleteOutline,
  EditOutlined,
} from "@mui/icons-material";
import { getEmailTemplate } from "app/utils/api-urls/AdminAPiUrls";
import { useMutation, useQuery } from "@tanstack/react-query";
import { instance } from "app/utils/axiosRequest";
import AddTemplateModalComponent from "./AddTemplateModalComponent";
import DeleteTemplateDialog from "./DeleteTemplateDialog";
import EditTemplateModalComponent from "./EditTemplateModal";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import { API_ENDPOINTS } from "app/utils/api-urls/allUrls";

const TemplateCard = () => {
  const [cardData, setCardData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [templateIdToDelete, setTemplateIdToDelete] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [templateToEdit, setTemplateToEdit] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const { refetch, isLoading, isRefetching } = useQuery(
    ["get-template-list", tabValue === 0 ? "PENDING" : "SOLVED"],
    ({ queryKey }) => {
      const [_key, type] = queryKey;
      return instance.get(`${API_ENDPOINTS.TEMPLATE.GET_ALL}?type=${type}`);
    },
    {
      onSuccess: (data) => {
        setCardData(data?.data?.data);
      },
    }
  );

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    refetch();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDeleteClick = (templateId) => {
    setTemplateIdToDelete(templateId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setTemplateIdToDelete(null);
  };

  const handleEditClick = (templateData) => {
    setTemplateToEdit(templateData);
    setEditOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditOpen(false);
    setTemplateToEdit(null);
  };

  const chunkArray = (array, size) => {
    const chunkedArr = [];
    let index = 0;
    while (index < array.length) {
      chunkedArr.push(array.slice(index, size + index));
      index += size;
    }
    return chunkedArr;
  };

  const cardDataChunks = chunkArray(cardData, 3);

  return (
    <>
      <FullpageLoader open={isLoading || isRefetching} />
      <AddTemplateModalComponent
        open={open}
        handleCloseModal={handleCloseModal}
        refetch={refetch}
        tabValue={tabValue}
      />
      <DeleteTemplateDialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        templateId={templateIdToDelete}
        refetch={refetch}
      />
      <EditTemplateModalComponent
        open={editOpen}
        handleCloseModal={handleCloseEditModal}
        refetch={refetch}
        templateData={templateToEdit}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Pending" />
          <Tab label="Solved" />
        </Tabs>
        <Tooltip title="Add new template">
          <IconButton onClick={handleOpen}>
            <AddCircleOutlineOutlined
              sx={{ fontSize: "35px", color: "#1F4172" }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      {cardDataChunks?.map((chunk, chunkIndex) => (
        <Box
          key={chunkIndex}
          marginTop={chunkIndex === 0 ? "1rem" : "2rem"}
          marginBottom="1rem"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          {chunk?.map((data, index) => (
            <Card key={index} sx={{ width: "30%", height: "250px" }}>
              <CardContent
                sx={{
                  height: "250px",
                  scrollbarWidth: "thin",
                  scrollbarColor: "#888 #f1f1f1",
                  "&::-webkit-scrollbar": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                    borderRadius: "8px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#888",
                    borderRadius: "8px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                  overflowY: "auto",
                }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography variant="h6" fontSize="18px">
                    {data.title}
                  </Typography>
                  <Box display="flex" flexDirection="row" gap="1rem">
                    <Tooltip title="Delete Template">
                      <IconButton onClick={() => handleDeleteClick(data?._id)}>
                        <DeleteOutline sx={{ color: "#FF6969" }} />
                      </IconButton>
                    </Tooltip>
                    <IconButton onClick={() => handleEditClick(data)}>
                      <EditOutlined sx={{ color: "#596E79" }} />
                    </IconButton>
                  </Box>
                </Box>

                <Divider sx={{ marginTop: "0.5rem" }} />
                <Box sx={{ marginTop: "0.5rem" }}>
                  <Typography variant="span" fontSize="14px">
                    {data.message}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      ))}
    </>
  );
};

export default TemplateCard;
