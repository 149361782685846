import {
  Person,
  Badge,
  PendingActions,
  CheckCircle,
  Grid3x3,
  Phone,
  PlayCircleFilledWhite,
} from "@mui/icons-material";
import {
  Modal,
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
  IconButton,
  Tooltip,
  Select,
  TextField,
  MenuItem,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import ReactAudioPlayer from "react-audio-player";
import { instance } from "app/utils/axiosRequest";
import {
  agentcategoryList,
  agentlistenRecording,
  agentlistenVoicemail,
} from "app/utils/api-urls/AgentApiUrls";
import { useQuery } from "@tanstack/react-query";
import {
  adminlistenRecording,
  updateTicket,
} from "app/utils/api-urls/AdminAPiUrls";
import { supervisorlistenRecording } from "app/utils/api-urls/SupervisorAPiUrls";
import { API_ENDPOINTS } from "app/utils/api-urls/allUrls";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  maxHeight: 600,
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 6,
  borderRadius: 2,
};

const UpdateCategoryModal = ({
  openModal,
  handleUpdateCategoryClose,
  UpdateCategory,
  refetch,
  followupData,
  setfollowupData,
}) => {
  const userRole = localStorage.getItem("role");
  const [showPlayer, setShowPlayer] = useState(false);
  const [audioSrc, setAudioSrc] = useState(null);
  const [selectedRecordingId, setSelectedRecordingId] = useState(null);
  const [loadingAudio, setLoadingAudio] = useState(false);
  const [selecteditem, setSelecteditem] = useState();
  const [issueCategories, setIssueCategories] = useState();
  const [subissueCategories, setSubIssueCategories] = useState();

  const [issueDetails, setIssueDetails] = useState({
    issue: "",
    sub_issue: "",
    remarks: "",
    status: "pending",
    sendNotification: false,
  });

  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "",
    message: "",
  });

  // get issue categories
  const { data, error } = useQuery(["issue-categories"], () =>
    instance.get(API_ENDPOINTS.ISSUE.CREATE)
  );

  useEffect(() => {
    if (selecteditem) {
      instance
        .get(`${API_ENDPOINTS.ISSUE.CREATE}/${selecteditem}`)
        .then((response) => {
          setSubIssueCategories(response.data);
        });
    }
    data && setIssueCategories(data?.data);
    //to reset src after modal is not on
    if (!openModal) {
      // if the modal is not open
      setAudioSrc(null); // reset audio source
      setSelectedRecordingId(null); // reset the recording ID
      setShowPlayer(false); // hide the player
    }
  }, [selecteditem, openModal, data]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({
      open: false,
      severity: "",
      message: "",
    });
  };
  const handleOptionChange = (e) => {
    const { name, value } = e.target;
    if (name === "issue") {
      // Clear the sub-issue category when the issue category is changed
      setIssueDetails((prev) => ({
        ...prev,
        issue: value,
        sub_issue: "",
      }));
      setSelecteditem(value); // This will trigger the API call for sub-issue category
    } else {
      setIssueDetails((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleRemarksChange = (e) => {
    const { name, value } = e.target;
    setIssueDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const updateTicketCategory = async () => {
    let payload = {
      ...issueDetails,
    };
    try {
      let response = await instance.patch(
        `${API_ENDPOINTS.TICKET.CREATE}/${UpdateCategory?._id}`,
        payload
      );
      if (response?.status === 200) {
        setSnackBar({
          open: true,
          severity: "success",
          message: "Category Updated successfully",
        });
        setIssueDetails({
          issue: "",
          sub_issue: "",
          remarks: "",
          status: "pending",
          sendNotification: false,
        });
      }
    } catch (error) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Error occured while creating ticket",
      });
    }
  };

  const handleSave = async () => {
    await updateTicketCategory();
    handleUpdateCategoryClose();
    setfollowupData({
      openModal: true,
      followData: UpdateCategory,
    });
    refetch();
  };

  //api call to listen recordng
  const handleAudioClick = async (recordingId) => {
    try {
      if (selectedRecordingId === recordingId) {
        setSelectedRecordingId(null);
        setAudioSrc(null);
        setShowPlayer(false); // Hide player
      } else {
        const response = await instance.get(
          `${API_ENDPOINTS.TICKET.GET_RECORDING}/${recordingId}`,
          {
            responseType: "arraybuffer",
          }
        );

        const blob = new Blob([response.data], { type: "audio/wav" });
        const audioUrl = URL.createObjectURL(blob);

        setSelectedRecordingId(recordingId);
        setAudioSrc(audioUrl);
        setShowPlayer(true);
      }
    } catch (error) {
      console.error("Error fetching audio recording:", error);
    }
  };

  //to listen voicemail recording
  const handleVoiceMailAudioClick = async (recordingId) => {
    try {
      setLoadingAudio(true);
      if (selectedRecordingId === recordingId) {
        setSelectedRecordingId(null);
        setAudioSrc(null);
        setShowPlayer(false);
      } else {
        const adminApiURL = `${adminlistenRecording.admin_listen_recording}/${recordingId}`;
        const agentApiURL = `${agentlistenVoicemail.agent_voicemail_recording}/${recordingId}`;
        const supervisorApiUrl = `${supervisorlistenRecording.supervisor_listen_recording}/${recordingId}`;
        const apiUrl =
          userRole === "admin"
            ? adminApiURL
            : userRole === "agent"
            ? agentApiURL
            : userRole === "supervisor"
            ? supervisorApiUrl
            : null;

        const response = await instance.get(apiUrl, {
          responseType: "arraybuffer",
        });

        const blob = new Blob([response.data], { type: "audio/wav" });
        const audioUrl = URL.createObjectURL(blob);

        setSelectedRecordingId(recordingId);
        setAudioSrc(audioUrl);
        setShowPlayer(true); // Show player
      }
      setLoadingAudio(false);
    } catch (error) {
      console.error(error);
      setLoadingAudio(false);
      setSnackBar({
        open: true,
        message: "Error fetching audio recording.",
      });
    }
  };

  return (
    <>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackBar.severity}
          sx={{ width: "100%" }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
      <Modal
        open={openModal}
        onClose={handleUpdateCategoryClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="flex-end" gap="50px">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="10px"
            >
              <Box display="flex" alignItems="center" gap="5px">
                <Person />
                <Typography variant="span">
                  {UpdateCategory?.clientName}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="5px">
                <Badge />
                <Typography variant="span">
                  {UpdateCategory?.clientId}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="5px">
                <Phone />
                <Typography variant="span">
                  {UpdateCategory?.clientPhone}
                </Typography>
              </Box>
            </Box>

            <Box>
              {UpdateCategory?.status === "pending" ? (
                <PendingActions sx={{ color: "#E7625F", fontSize: "30px" }} />
              ) : UpdateCategory?.status === "open" ? (
                <FileOpenOutlined sx={{ color: "#27374D", fontSize: "30px" }} />
              ) : (
                <CheckCircle sx={{ color: "#81B622", fontSize: "30px" }} />
              )}
            </Box>
          </Box>
          <Card sx={{ marginTop: "1em" }}>
            <CardContent sx={{ marginTop: "1em" }}>
              <Box position="fixed" top="21px">
                <Avatar
                  src={`https:kyc.naasasecurities.com.np/${UpdateCategory?.clientImage}`}
                  alt={UpdateCategory?.clientName}
                  sx={{ width: 100, height: 100 }}
                />
              </Box>
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  color={
                    UpdateCategory?.status === "pending"
                      ? "#E7625F"
                      : data?.status === "open"
                      ? "#303481"
                      : "#81B622"
                  }
                  marginBottom="10px"
                >
                  <Box display="flex" alignItems="center">
                    <Grid3x3 />
                    <Typography variant="span">
                      {UpdateCategory?.ticketId}
                    </Typography>
                  </Box>
                  {loadingAudio ? (
                    <CircularProgress />
                  ) : showPlayer ? (
                    <ReactAudioPlayer
                      controls
                      src={audioSrc}
                      autoPlay
                      style={{ height: "40px" }}
                    />
                  ) : null}
                  {UpdateCategory?.type === "NORMAL" && (
                    <Tooltip
                      arrow
                      placement="top"
                      size="lg"
                      title="Play Call Recording"
                    >
                      <IconButton
                        onClick={() => handleAudioClick(UpdateCategory?._id)}
                        sx={{ color: "#213555" }}
                        aria-label="play"
                      >
                        <PlayCircleFilledWhite />
                      </IconButton>
                    </Tooltip>
                  )}
                  {UpdateCategory?.type === "VOICEMAIL" && (
                    <Tooltip
                      arrow
                      placement="top"
                      size="lg"
                      title="Play Voice Mail Recording"
                    >
                      <IconButton
                        onClick={() =>
                          handleVoiceMailAudioClick(
                            UpdateCategory?.voicemailLogId
                          )
                        }
                        sx={{ color: "#765827" }}
                        aria-label="play"
                      >
                        <PlayCircleFilledWhite />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>

                <Box
                  display="flex"
                  flexWrap="wrap"
                  fontSize="13px"
                  justifyContent="space-between"
                  gap="10px"
                >
                  {UpdateCategory?.isProxy && (
                    <>
                      <Box width="45%">
                        <Typography fontSize="18px" variant="span">
                          Proxy Name:{" "}
                        </Typography>
                        <Typography fontSize="18px" variant="span">
                          {UpdateCategory?.proxyName}
                        </Typography>
                      </Box>
                    </>
                  )}

                  <Box width="45%">
                    <Typography fontSize="18px" variant="span">
                      Opened By:{" "}
                    </Typography>
                    <Typography fontSize="18px" variant="span">
                      {UpdateCategory?.openedBy}
                    </Typography>
                  </Box>
                  <Box width="45%">
                    <Typography fontSize="18px" variant="span">
                      Notification Status:
                    </Typography>
                    <Typography fontSize="18px" variant="span">
                      {UpdateCategory?.notificationStatus}
                    </Typography>
                  </Box>
                  {/* //multiple remarks */}
                  <Box width="100%">
                    <Box display="flex" flexDirection="column">
                      <Typography fontSize="18px" variant="span">
                        Remarks:
                      </Typography>
                      {UpdateCategory?.remarksList?.map((remark, index) => (
                        <Card sx={{ marginTop: "1em" }}>
                          <CardContent>
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap="1px"
                            >
                              <Typography fontSize="18px" variant="span">
                                {remark.remarks}
                              </Typography>
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                gap="10px"
                              >
                                <Typography
                                  fontSize="12px"
                                  fontStyle="italic"
                                  variant="caption"
                                >
                                  {remark.updatedBy}
                                </Typography>
                                <Typography
                                  fontSize="12px"
                                  variant="caption"
                                  fontStyle="italic"
                                >
                                  {dayjs(remark.updatedAt).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      ))}
                    </Box>
                  </Box>
                  {/* for issue category and sub issue category */}

                  <Card
                    sx={{
                      width: "100%",
                      backgroundColor: "#f9f9f9",
                      margin: "1em 0",
                    }}
                  >
                    <CardContent>
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap="20px"
                        marginTop="1em"
                      >
                        <Box display="flex" alignItems="center">
                          <Typography variant="span" width="40%">
                            Issue Category
                          </Typography>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            size="small"
                            value={issueDetails.issue}
                            name="issue"
                            onChange={handleOptionChange}
                            width="60%"
                            fullWidth
                            required
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                          >
                            {issueCategories?.map((item) => (
                              <MenuItem value={item?._id} key={item?.name}>
                                {item?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Typography variant="span" width="40%">
                            Sub Issue Category*
                          </Typography>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            size="small"
                            value={issueDetails.sub_issue}
                            name="sub_issue"
                            onChange={handleOptionChange}
                            width="60%"
                            fullWidth
                            required
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                          >
                            {subissueCategories?.map((item) => (
                              <MenuItem value={item?._id} key={item?.name}>
                                {item?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Typography variant="span" width="40%">
                            Remarks #
                          </Typography>
                          <TextField
                            variant="outlined"
                            name="remarks"
                            size="small"
                            value={issueDetails.remarks}
                            onChange={handleRemarksChange}
                            multiline
                            rows={2}
                            width="60%"
                            fullWidth
                            required
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                          />
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
                <Box mt={3} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      issueDetails.issue === "" ||
                      issueDetails.sub_issue === "" ||
                      issueDetails.remarks === ""
                    }
                    onClick={handleSave}
                  >
                    Update Ticket
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </>
  );
};

export default UpdateCategoryModal;
