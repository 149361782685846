import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { instance } from "app/utils/axiosRequest";
import { getEmailTemplate } from "app/utils/api-urls/AdminAPiUrls";
import { API_ENDPOINTS } from "app/utils/api-urls/allUrls";

const EditTemplateModalComponent = ({
  open,
  handleCloseModal,
  refetch,
  templateData,
}) => {
  const [formData, setFormData] = useState({ title: "", message: "" });
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "",
    message: "",
  });

  useEffect(() => {
    if (templateData) {
      setFormData({ title: templateData.title, message: templateData.message });
    }
  }, [templateData]);

  const {
    mutate: updateEmailTemplate,
    isLoading,
    isRefetching,
  } = useMutation(
    ["update-template"],
    () =>
      instance.patch(
        `${API_ENDPOINTS.TEMPLATE.CREATE}/${templateData._id}`,
        formData
      ),
    {
      onSuccess: () => {
        refetch();
        handleCloseModal();
        setSnackBar({
          open: true,
          severity: "success",
          message: "Template updated successfully!",
        });
      },
      onError: () => {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Error updating template!",
        });
      },
    }
  );

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({ ...snackBar, open: false });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = () => {
    updateEmailTemplate();
  };

  return (
    <>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={snackBar.severity}
          sx={{ width: "100%" }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Template
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="title"
            label="Title"
            name="title"
            autoFocus
            value={formData.title}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="message"
            label="Message"
            id="message"
            multiline
            rows={4}
            value={formData.message}
            onChange={handleChange}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              color="primary"
              disabled={!formData.title.trim() || !formData.message.trim()}
            >
              {isLoading || isRefetching ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default EditTemplateModalComponent;
