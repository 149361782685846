import TicketPageV2 from "app/pages/TicketPageV2";
import TicketReportV2 from "app/pages/TicketReportV2";
import AssignedToMe from "app/pages/assignedToMe";
import AgentTicketReportPage from "app/pages/ticket/AgentTicketReportPage";
import { lazy } from "react";

// Ticket

const CreateTicketNew = lazy(() => import("app/pages/ticket/createNew"));
const TicketQuery = lazy(() => import("app/pages/ticket/NewTicketList"));

const setupRoutes = [
  {
    path: "/app/ticket/create-ticket-new",
    shortPath: "/ticket/create-ticket-new",
    name: "Create Ticket",
    icon: "mdi mdi-pen",
    component: CreateTicketNew,
    hideInSidebar: true,
  },

  {
    path: "/app/v2/create-ticket",
    shortPath: "/v2/ticket/create-ticket",
    name: "Create Ticket v2",
    icon: "mdi mdi-clipboard-list-outline",
    component: TicketPageV2,
    hideInSidebar: true,
  },

  {
    path: "/app/ticket/ticket-list",
    shortPath: "Admin/ticket/ticket-list",
    name: "All Tickets  ",
    icon: "mdi mdi-clipboard-list-outline",
    component: TicketQuery,
  },

  {
    path: "/app/ticket/ticket-listV2",
    shortPath: "Admin/ticket/ticket-listV2",
    name: "Ticket Report V2",
    icon: "mdi mdi-clipboard-list-outline",
    component: TicketReportV2,
    hideInSidebar: true,
  },

  {
    path: "/app/ticket/assigned-to-me",
    shortPath: "Admin/ticket/assigned-to-me",
    name: "Assigned To Me",
    component: AssignedToMe,
    icon: "mdi mdi-clipboard-alert",
  },
];

export default setupRoutes;
