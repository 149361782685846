import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Typography,
  Grid,
} from "@mui/material";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { instance } from "app/utils/axiosRequest";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import CallSummary from "app/pages/dashboard/AdminDashboard/CallSummary";
import {
  adminCallHistoryReport,
  allQueueList,
  queueCallLogs,
} from "app/utils/api-urls/AdminAPiUrls";
import qs from "qs";
import CommonReportFilter from "app/pages/AdminDashboard/CommonReportFilter";
import QueueCallLogsTable from "./QueueCallLogsTable";
import { API_ENDPOINTS } from "app/utils/api-urls/allUrls";

const QueueCallLogs = () => {
  const [filterData, setFilterData] = useState({
    from: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
    queue: "SYSTEM",
    source: "",
    page: 1,
    limit: 20,
  });

  //Fetch call history list
  const { data, isLoading, error, refetch, isRefetching } = useQuery(
    ["queue-call-logs-report", , filterData],
    () =>
      instance.get(
        API_ENDPOINTS.QUEUE_LOG.GET_REPORT + "?" + qs.stringify(filterData)
      )
  );

  const { data: queueList } = useQuery(["queue-list", , filterData], () =>
    instance.get(allQueueList.allqueue_list)
  );
  //to add default system queue
  const additionalData = [
    {
      id: "SYSTEM",
      value: "SYSTEM",
      label: "System",
    },
  ];

  const mappedData = [
    ...additionalData,
    ...(queueList?.data?.map((item) => ({
      id: item?.number,
      value: item?.number,
      label: item?.name,
    })) || []),
  ];

  //pagination left click
  const handleLeftClick = () => {
    setFilterData({
      ...filterData,
      page: --filterData.page,
    });
    refetch();
  };
  //pagination right click
  const handleRightClick = () => {
    setFilterData({
      ...filterData,
      page: ++filterData.page,
    });
    refetch();
  };

  const handleFirstPageClick = () => {
    setFilterData({
      ...filterData,
      page: 1,
    });
    refetch();
  };

  const handleLastPageClick = () => {
    setFilterData({
      ...filterData,
      page: data?.data?.totalPages,
    });
    refetch();
  };

  const handlePageClick = (page) => {
    setFilterData((prevState) => ({
      ...prevState,
      page: page,
    }));
    refetch();
  };

  const handleChange = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFromDate = (value) => {
    setFilterData({
      ...filterData,
      from: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleToDate = (value) => {
    setFilterData({
      ...filterData,
      to: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleClear = () => {
    setFilterData({
      from: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
      to: dayjs().format("YYYY-MM-DD"),
      queue: "SYSTEM",
      source: "",
      page: 1,
      limit: 20,
    });
    refetch();
  };

  const handleFilter = () => {
    refetch();
  };

  return (
    <>
      <FullpageLoader open={isLoading} />

      <Box display="flex" gap="10px" width="100%" marginTop="1em">
        <Card sx={{ width: "25%" }}>
          <CardContent>
            <CommonReportFilter
              filterData={filterData}
              handleChange={handleChange}
              handleFromDate={handleFromDate}
              handleToDate={handleToDate}
              handleClear={handleClear}
              handleFilter={handleFilter}
              isLoading={isRefetching}
              queueOptions={mappedData}
              reportType="queueCallLogs"
            />
          </CardContent>
        </Card>
        <Box width="75%">
          <Card sx={{ height: "80vh" }}>
            <CardContent>
              <Box
                display="flex"
                flex="1"
                flexDirection="rows"
                justifyContent="space-between"
                marginBottom="5px"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  gap="5px"
                  padding="2px"
                  color="#000000"
                  borderRadius="10px"
                  border="2px solid #285430"
                  height="2.5em"
                  width="10em"
                  justifyContent="center"
                >
                  {" "}
                  Count:- {data?.data?.totalData.toLocaleString()} Calls{" "}
                </Box>
              </Box>

              <QueueCallLogsTable
                tableData={data?.data}
                tableDataLoading={isLoading}
                currentPage={filterData?.page}
                handleLeftClick={handleLeftClick}
                handleRightClick={handleRightClick}
                handleFirstPageClick={handleFirstPageClick}
                handleLastPageClick={handleLastPageClick}
                handlePageClick={handlePageClick}
              />
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default QueueCallLogs;
