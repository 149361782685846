import SupervisorTicketReport from "app/pages/SupervisorReport/TicketReport/TicketReport";
import TicketPageV2 from "app/pages/TicketPageV2";
import TicketReportV2 from "app/pages/TicketReportV2";
import AssignedToMe from "app/pages/assignedToMe";
const SupervisorTicketReportRoutes = [
  {
    path: "/app/ticket/supervisor-report",
    shortPath: "/ticket/ticket-list",
    name: "Ticket List",
    icon: "mdi mdi-clipboard-list-outline",
    component: SupervisorTicketReport,
  },
  {
    path: "/app/ticket/assigned-me",
    shortPath: "/ticket/assigned--me",
    name: "Assigned-to-me",
    icon: "mdi mdi-clipboard-list-outline",
    component: AssignedToMe,
  },
  {
    path: "/app/ticket/ticket-listV2",
    shortPath: "/ticket/ticket-listV2",
    name: "Superivisor Ticket Report V2",
    icon: "mdi mdi-clipboard-list-outline",
    component: TicketReportV2,
    hideInSidebar: true,
  },
  {
    path: "/app/v2/create-ticket/:ticketId",
    shortPath: "/v2/ticket/create-ticket",
    name: "Create Ticket v2",
    icon: "mdi mdi-clipboard-list-outline",
    component: TicketPageV2,
    hideInSidebar: true,
  },
  {
    path: "/app/v2/create-ticket",
    shortPath: "/v2/ticket/create-ticket",
    name: "Create Ticket v2",
    icon: "mdi mdi-clipboard-list-outline",
    component: TicketPageV2,
    hideInSidebar: true,
  },
];

export default SupervisorTicketReportRoutes;
