const BASE_PATH = "/api/v1";
const BASE_PATHV2 = "/api/v2";

export const API_ENDPOINTS = {
  ISSUE: {
    GET_ALL: `${BASE_PATH}/issue`,
    GET_BY_ID: (id) => `${BASE_PATH}/issue/${id}`,
    CREATE: `${BASE_PATH}/issue`,
    UPDATE: (id) => `${BASE_PATH}/issue/update-issue/${id}`,
    DELETE: (id) => `${BASE_PATH}/issue/${id}`,
    ADD_SUBISSUE: (id) => `${BASE_PATH}/issue/${id}/add-subissue`,
    REMOVE_SUBISSUE: (id) => `${BASE_PATH}/issue/${id}/remove-subissue`,
  },
  SUB_ISSUE: {
    GET_ALL: `${BASE_PATH}/subissue`,
    CREATE: `${BASE_PATH}/subissue`,
  },
  TICKET: {
    GET_BY_ID: (id) => `${BASE_PATH}/ticket/${id}`,
    GET_RECORDING: (id) => `${BASE_PATH}/ticket/recording/${id}`,
    GET_BY_PHONE: (phone) => `${BASE_PATH}/ticket/phone/${phone}`,
    DELETE: (id) => `${BASE_PATH}/ticket/${id}`,
    UPDATE: (id) => `${BASE_PATH}/ticket/${id}`,
    CREATE: `${BASE_PATH}/ticket`,
    UPLOAD: `${BASE_PATH}/ticket/upload`,
    BULK_UPDATE: `${BASE_PATH}/ticket/bulk-ticket`,
    PICK: `${BASE_PATH}/ticket/pick`,
    SOLVE: `${BASE_PATH}/ticket/solve`,
    ASSIGN: `${BASE_PATH}/ticket/assign`,
    ASSIGN_DEPARTMENT: `${BASE_PATH}/ticket/assign-ticket-department`,
  },
  BREAK: {
    GET_ALL: `${BASE_PATH}/break`,
    GET_BY_ID: (id) => `${BASE_PATH}/break/${id}`,
    CREATE: `${BASE_PATH}/break`,
    UPDATE: (id) => `${BASE_PATH}/break/${id}`,
    DELETE: (id) => `${BASE_PATH}/break/${id}`,
  },
  USER: {
    GET_ALL: `${BASE_PATH}/user`,
    GET_BY_ID: (id) => `${BASE_PATH}/user/${id}`,
    DELETE: (id) => `${BASE_PATH}/user/${id}`,
    DELETE_SUPERVISOR: (id) => `${BASE_PATH}/user/supervisor/${id}`,
    GET_EXTENSION_STATUS: `${BASE_PATH}/user/extension-status`,
    GET_PROFILE: `${BASE_PATH}/user/user-profile`,
    GET_AGENT: `${BASE_PATH}/user/agent`,
    GET_AGENT_BY_ID: (id) => `${BASE_PATH}/user/agent/${id}`,
    GET_TYPE: `${BASE_PATH}/user/type`,
    ADD_AGENT: `${BASE_PATH}/user/agent/add`,
    UPDATE_USER: (id) => `${BASE_PATH}/user/${id}`,
    CHANGE_PASSWORD: `${BASE_PATH}/user/change-password`,
    CHANGE_PASSWORD_BY_ID: (id) => `${BASE_PATH}/user/change-password/${id}`,
    USER_BREAK: `${BASE_PATH}/user/break`,
    CREATE: `${BASE_PATH}/user`,
    CREATE_AGENT: `${BASE_PATH}/user/agent`,
    CREATE_SUPERVISOR: `${BASE_PATH}/user/supervisor`,
  },
  CALL_LOGS: {
    GET_RECORDING: `${BASE_PATH}/call/recording`,
    GET_REPORT: `${BASE_PATH}/call/report`,
  },
  TICKET_REPORT: {
    GET_TICKET: `${BASE_PATH}/report/ticket`,
    GET_ASSIGNED_TICKET: `${BASE_PATH}/report/ticket/assigned-ticket`,
    GET_DOWNLOAD: `${BASE_PATH}/report/ticket/download`,
    GET_VOICEMAIL: `${BASE_PATH}/report/ticket/voicemail`,
    GET_STATS_DASHBOARD: `${BASE_PATH}/report/ticket/stats/dashboard`,
    GET_STATS_AGENT: `${BASE_PATH}/report/ticket/stats/agent`,
    GET_STATS_AGENT_SELF: `${BASE_PATH}/report/ticket/stats/agent/self`,
  },
  QUEUE: {
    GET_ALL: `${BASE_PATH}/queue`,
    GET_BY_ID: (id) => `${BASE_PATH}/queue/${id}`,
    UPDATE: (id) => `${BASE_PATH}/queue/${id}`,
    ADD_USER: (id) => `${BASE_PATH}/queue/add-user/${id}`,
    CREATE: `${BASE_PATH}/queue`,
  },
  TEMPLATE: {
    GET_ALL: `${BASE_PATH}/template`,
    GET_BY_ID: (id) => `${BASE_PATH}/template/${id}`,
    UPDATE: (id) => `${BASE_PATH}/template/${id}`,
    CREATE: `${BASE_PATH}/template`,
    DELETE: (id) => `${BASE_PATH}/template/${id}`,
  },
  QUEUE_LOG: {
    GET_STATS: `${BASE_PATH}/queue-log/stats`,
    GET_REPORT: `${BASE_PATH}/queue-log/report`,
    GET_ABANDONED: `${BASE_PATH}/queue-log/abandoned`,
    UPDATE: (id) => `${BASE_PATH}/queue-log/${id}`,
  },
  BREAKV2: {
    GET_ALL: `${BASE_PATHV2}/break`,
    GET_BY_ID: (id) => `${BASE_PATHV2}/break/${id}`,
    TAKE_A_BREAK: `${BASE_PATHV2}/break`,
  },
};
