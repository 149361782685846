import React, { useState } from "react";

import {
  Box,
  Typography,
  Button,
  Tooltip,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useMutation, useQuery } from "@tanstack/react-query";
import { instance } from "app/utils/axiosRequest";
import {
  AssignmentInd,
  ConfirmationNumber,
  ContactPhone,
  Download,
  Edit,
  Preview,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { useEffect } from "react";
import CommonReportFilter from "app/pages/AdminDashboard/CommonReportFilter";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import CustomPagination from "app/shared/Pagination/Pagination.component";
import { ticketList } from "app/utils/api-urls/AgentApiUrls";
import {
  adminTicketList,
  allcategoryList,
} from "app/utils/api-urls/AdminAPiUrls";
import qs from "qs";
import {
  SupervisorChatTicketReportApi,
  SupervisorTicketReportApi,
  SupervisorVoicemailTicketReportApi,
  supervisorallAgent,
  supervisorallcategoryList,
  supervisordownloadTickets,
  supervisorpickTicketapi,
} from "app/utils/api-urls/SupervisorAPiUrls";
import ViewSupervisorTicketModal from "./ViewSupervisorTicketModal";
import ViewTicketModal from "app/pages/ticket/NewTicketList/ViewTicketModal";
import FollowUpTIcketModal from "app/pages/ticket/NewTicketList/FollowUpTIcketModal";
import { useHistory } from "react-router-dom";
import ChatPickTicketModal from "app/pages/ticket/PickTicketPage";
import BulkEditModal from "app/components/BulkEditModalComponent/BulkEditModalComponent";
import { API_ENDPOINTS } from "app/utils/api-urls/allUrls";

const SupervisorTicketReport = () => {
  const history = useHistory();

  const [bulkEditModalOpen, setBulkEditModalOpen] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const [ticketData, setTicketData] = useState({
    open: false,
    data: null,
  });

  const [followupData, setfollowupData] = useState({
    openModal: false,
    followData: null,
  });

  const [pickchaticketData, setpickchaticketData] = useState({
    openModal: false,
    pickData: null,
  });

  const [adminFilterData, setAdminFilterData] = useState({
    status: "",
    from: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
    page: 1,
    issue: "",
    phone: "",
    type: "NORMAL",
    email: "",
    assignedTo: "",
    clientId: "",
    extension: "",
    limit: 20,
  });

  const { isLoading, data, error, refetch, isFetching } = useQuery(
    ["supervisor_ticket_list", adminFilterData],
    () =>
      instance.get(
        API_ENDPOINTS.TICKET_REPORT.GET_TICKET +
          "?" +
          qs.stringify(adminFilterData)
      ),
    {
      enabled: true,
    }
  );

  const { data: supervisorAgentData } = useQuery(
    ["supervisor-agent-data"],
    () => instance.get(API_ENDPOINTS.USER.GET_AGENT)
  );

  const { mutate: downloadTicket, isLoading: isDownloading } = useMutation(
    () =>
      instance.get(
        API_ENDPOINTS.TICKET_REPORT.GET_DOWNLOAD +
          "?" +
          qs.stringify(adminFilterData),
        { responseType: "blob" }
      ),
    {
      onSuccess: (response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "text/csv" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Tickets.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      },
      onError: (error) => {
        console.error("Download error:", error);
      },
    }
  );

  const handleDownloadTicket = () => {
    if (!isDownloading) {
      downloadTicket();
    }
  };

  const supervisormappedData = supervisorAgentData?.data?.map((item) => ({
    id: item?._id,
    value: item?.extension,
    label: item?.firstName,
  }));

  const handleSelectionChange = (selectionModel) => {
    setSelectedRowIds(selectionModel);
  };

  //pagination left click
  const handleLeftClick = () => {
    setAdminFilterData({
      ...adminFilterData,
      page: --adminFilterData.page,
    });
    refetch();
  };

  //pagination right click
  const handleRightClick = () => {
    setAdminFilterData({
      ...adminFilterData,
      page: ++adminFilterData.page,
    });
    refetch();
  };

  const handleFirstPageClick = () => {
    setAdminFilterData({
      ...adminFilterData,
      page: 1,
    });
    refetch();
  };

  const handleLastPageClick = () => {
    setAdminFilterData({
      ...adminFilterData,
      page: data?.data?.totalPages,
    });
    refetch();
  };

  const handlePageClick = (page) => {
    setAdminFilterData((prevState) => ({
      ...prevState,
      page: page,
    }));
    refetch();
  };

  const handleViewTicket = (data) => {
    data &&
      setTicketData({
        open: true,
        data: data,
      });
  };

  const handleTicketClose = () => {
    setTicketData({
      open: false,
      data: null,
    });
  };

  const handlePickTicketClick = (data) => {
    const payload = {
      type: data?.type,
    };

    setpickchaticketData({
      ...pickchaticketData,
      pickData: data,
    });

    pickTicketMutation({ data, payload });
  };

  const handleClosePickTicketPage = () => {
    setpickchaticketData({
      openModal: false,
      pickData: null,
    });
  };

  const handleViewFollowUpPage = (followData) => {
    setfollowupData({
      openModal: true,
      followData: followData,
    });
  };

  const handleCloseFollowUpPage = () => {
    setfollowupData({
      openModal: false,
      followData: null,
    });
  };

  const handleAdminFilterChange = (event) => {
    setAdminFilterData({
      ...adminFilterData,
      [event.target.name]: event.target.value,
    });
  };

  const handleAdminToDate = (value) => {
    setAdminFilterData({
      ...adminFilterData,
      to: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleAdminFromDate = (value) => {
    setAdminFilterData({
      ...adminFilterData,
      from: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleClear = () => {
    setAdminFilterData({
      status: "",
      from: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
      to: dayjs().format("YYYY-MM-DD"),
      page: 1,
      email: "",
      type: "NORMAL",
      assignedTo: "",
      clientId: "",
      issue: "",
      phone: "",
      extension: "",
    });
  };

  const handleFilter = () => {
    refetch();
  };

  const statusOptions = [
    {
      id: 1,
      value: "pending",
      label: "Pending",
    },
    {
      id: 2,
      value: "closed",
      label: "Solved",
    },
    {
      id: 3,
      value: "open",
      label: "Open",
    },
  ];

  const supervisorTicketType = [
    {
      id: 1,
      value: "NORMAL",
      label: " Call",
    },
    {
      id: 2,
      value: "CHAT",
      label: "Naasa Chat",
    },
    // {
    //   id: 3,
    //   value: "NCELL",
    //   label: "Ncell Chat",
    // },
  ];

  const [issueCategories, setIssueCategories] = useState();

  const { data: data2, error: errorissueCategory } = useQuery(
    ["supervisor-report-issue-categories"],
    () => instance.get(API_ENDPOINTS.ISSUE.GET_ALL)
  );

  useEffect(() => {
    data2 && setIssueCategories(data2?.data);
  }, [data2]);

  const mappedData = issueCategories?.map((item) => ({
    id: item?._id,
    value: item?._id,
    label: item?.name,
  }));

  const isRowSelectable = (params) => {
    return params.row.status !== "closed";
  };

  const columns = [
    {
      field: "ticketId",
      headerName: "Ticket Id",
      renderCell: (cell) => {
        const ticketId = cell?.row?.ticketId;

        return (
          <Box
            display="flex"
            alignItems="center"
            gap="5px"
            backgroundColor="#232C93"
            padding="2px"
            color="#fff"
            borderRadius="10px"
            height="2em"
            width="7em"
            justifyContent="center"
          >
            <>
              <ConfirmationNumber sx={{ fontSize: "12px" }} />
              <Typography fontSize="12px" variant="span">
                {ticketId}
              </Typography>
            </>
          </Box>
        );
      },
      width: 150,
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 150,
      renderCell: (cell) => {
        const date = cell?.row?.createdAt;
        const newDate = dayjs(date).format("MMMM DD YYYY");
        return (
          <Typography fontSize="14px" variant="span">
            {newDate}
          </Typography>
        );
      },
    },
    {
      field: "time",
      headerName: "Time",
      width: 100,
      renderCell: (cell) => {
        const time = cell?.row?.createdAt;
        const dateObject = dayjs(time);
        const formattedTime = dateObject.format("HH:mm:ss");

        return (
          <Typography fontSize="14px" variant="span">
            {formattedTime}
          </Typography>
        );
      },
    },

    {
      field: "clientName",
      headerName: "Client Name",
      width: 150,
      Tooltip: true,
      renderCell: (cell) => {
        const clientName = cell?.row?.clientName;
        const clientId = cell?.row?.clientId;

        return (
          <Box display="flex" flexDirection="column">
            <Typography gutterBottom fontSize="12px" variant="span">
              {clientName}
            </Typography>
            <Typography gutterBottom fontSize="12px" variant="caption">
              {clientId}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "kycStatus",
      headerName: "KYC Status",
      width: 105,
      renderCell: (params) => {
        const tooltipData = params?.row?.kycStatus;

        return (
          <Tooltip title={tooltipData}>
            <Typography variant="span">{tooltipData}</Typography>
          </Tooltip>
        );
      },
    },

    {
      field: "phoneNumber",
      headerName: "Contact Details",
      width: 140,
      Tooltip: true,
      renderCell: (cell) => {
        const clientPhone = cell?.row?.clientPhone;
        const clientEmail = cell?.row?.clientEmail;
        return (
          <Box display="flex" flexDirection="column">
            <Typography gutterBottom fontSize="12px" variant="span">
              {clientPhone}
            </Typography>
            <Tooltip title={clientEmail}>
              <Typography gutterBottom fontSize="12px" variant="caption">
                {clientEmail}
              </Typography>
            </Tooltip>
          </Box>
        );
      },
    },

    {
      field: "openedBy",
      headerName: "Opened By",
      width: 100,
    },
    {
      field: "status",
      headerName: "Ticket Status",
      width: 105,
      renderCell: (params) => {
        const status = params?.row?.status;
        return (
          <Typography
            variant="span"
            color={
              status === "pending"
                ? "#E7625F"
                : status === "open"
                ? "#303481"
                : "#81B622"
            }
          >
            {status.toUpperCase()}
          </Typography>
        );
      },
    },
    {
      field: "issueCategory",
      headerName: "Issue Category",
      width: 150,
      valueGetter: (params) => params?.row?.issue?.name,
    },
    {
      field: "issueSubCategory",
      headerName: "Sub-Issue Category",
      width: 150,
      valueGetter: (params) => params?.row?.sub_issue?.name,
    },

    {
      headerName: "Actions",
      width: 150,
      renderCell: (params) => {
        const status = params?.row?.status;
        const data = params?.row;
        const followData = params?.row;
        const ticketType = params?.row?.type;

        return (
          <Box display="flex" alignItems="center" gap="15px">
            <Tooltip title="View Ticket">
              <Preview
                sx={{ color: "#C26DBC", cursor: "pointer" }}
                onClick={() => handleViewTicket(data)}
              />
            </Tooltip>
            {status === "pending" && (
              <Tooltip title="Follow Up">
                <ContactPhone
                  sx={{ color: "#A06204", cursor: "pointer" }}
                  onClick={() => handleViewFollowUpPage(followData)}
                />
              </Tooltip>
            )}
            {status === "open" && (
              <Tooltip title="Pick Ticket">
                <IconButton
                  size="small"
                  onClick={() => handlePickTicketClick(params.row)}
                >
                  <AssignmentInd sx={{ color: "#26577C" }} fontSize="medium" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];

  const { mutate: pickTicketMutation } = useMutation(
    (ticketInfo) => {
      const { data, payload } = ticketInfo;
      return instance.patch(
        `${supervisorpickTicketapi.supervisor_pickedup_ticket_api}/${data._id}`,
        payload
      );
    },
    {
      onSuccess: () => {
        setpickchaticketData((prevState) => ({
          ...prevState,
          openModal: true,
        }));
        refetch();
      },
      onError: (error) => {
        // Handle error
        console.error("Error picking ticket:", error);
      },
    }
  );

  return (
    <>
      <FullpageLoader open={isLoading || isFetching} />
      <ViewTicketModal
        open={ticketData.open}
        data={ticketData.data}
        handleClose={handleTicketClose}
      />
      <FollowUpTIcketModal
        openModal={followupData?.openModal}
        handleCloseFollowUpPage={handleCloseFollowUpPage}
        followData={followupData?.followData}
        refetch={refetch}
      />
      <ChatPickTicketModal
        openModal={pickchaticketData?.openModal}
        handleClosePickTicketPage={handleClosePickTicketPage}
        followData={pickchaticketData?.pickData}
        refetch={refetch}
      />
      <BulkEditModal
        open={bulkEditModalOpen}
        handleClose={() => setBulkEditModalOpen(false)}
        selectedIds={selectedRowIds}
        refetch={refetch}
      />
      <Box display="flex" gap="10px" width="100%" marginTop=".5em">
        <Card sx={{ width: "25%" }}>
          <CardContent>
            <CommonReportFilter
              filterData={adminFilterData}
              handleChange={handleAdminFilterChange}
              handleFromDate={handleAdminFromDate}
              handleToDate={handleAdminToDate}
              handleClear={handleClear}
              handleFilter={handleFilter}
              SupervisiorticketStatusOption={statusOptions}
              SupervisionIssueOptions={mappedData}
              supervisorAgentOptions={supervisormappedData}
              supervisorTicketType={supervisorTicketType}
              reportType="SuperVisorticketList"
            />
          </CardContent>
        </Card>
        <Card sx={{ height: "80vh", width: "75%" }}>
          <CardContent>
            <Box
              display="flex"
              flex="1"
              flexDirection="rows"
              justifyContent="space-between"
              marginBottom="5px"
            >
              <Box
                display="flex"
                alignItems="center"
                gap="5px"
                // backgroundColor="#59981A"
                padding="2px"
                color="#000000"
                borderRadius="10px"
                border="2px solid #285430"
                height="2.5em"
                width="10em"
                justifyContent="center"
              >
                {" "}
                Count:- {data?.data?.totalData.toLocaleString()} Tickets{" "}
              </Box>
              <Box display="flex" flexDirection="row" gap="1rem">
                {selectedRowIds.length > 1 && (
                  <Button
                    startIcon={<Edit />}
                    variant="contained"
                    onClick={() => setBulkEditModalOpen(true)}
                  >
                    Bulk Update
                  </Button>
                )}
                <Button
                  variant="contained"
                  sx={{ marginRight: "1rem", borderRadius: "5px" }}
                  startIcon={<Download />}
                  onClick={handleDownloadTicket}
                  disabled={isDownloading}
                >
                  Download
                </Button>
              </Box>
            </Box>
            <Box style={{ height: "67vh", width: "100%" }}>
              {!isLoading && data && (
                <>
                  <DataGrid
                    getRowId={(row) => row?._id}
                    rows={data?.data?.data}
                    columns={columns}
                    loading={isLoading}
                    hideFooter="true"
                    checkboxSelection // Enable checkbox selection
                    onSelectionModelChange={handleSelectionChange} // Handle selection changes
                    isRowSelectable={isRowSelectable}
                  />
                  <Box
                    display="flex"
                    justifyContent="space-between" // Adjusts children to be on the opposite sides
                    alignItems="center"
                  >
                    {/* Selected Ticket Count */}
                    <Typography fontSize="12px">
                      Selected: {selectedRowIds.length} Tickets
                    </Typography>

                    {/* Pagination */}
                    <Box display="flex" justifyContent="center" flex={1}>
                      <CustomPagination
                        currentPage={adminFilterData?.page}
                        totalPage={data?.data?.totalPages}
                        handleLeftClick={handleLeftClick}
                        handleRightClick={handleRightClick}
                        handleFirstPageClick={handleFirstPageClick}
                        handleLastPageClick={handleLastPageClick}
                        handlePageClick={handlePageClick}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
export default SupervisorTicketReport;
