import axios from "axios";
import { removeEmptyFields } from "./index";
import store from "app/store";
import socketIOClient from "socket.io-client";
import { logout } from "app/services/authService";

// Socket URL
export const socketURL = {
  liveUrl: process.env.REACT_APP_SOCKET_URL,
};

// Base Axios instance
const accessToken = localStorage.getItem("callCenterToken");
const refreshToken = localStorage.getItem("refresh_token");

const newBaseURL = process.env.REACT_APP_API_URL;
export const instance = axios.create({
  baseURL: newBaseURL,
  timeout: 30000,
});

// Helper function to check token expiration
const isTokenExpired = (token) => {
  if (!token) return true;
  const payload = JSON.parse(atob(token.split(".")[1]));
  const expiration = payload.exp;
  const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds
  return currentTime > expiration;
};

// Helper function to refresh the token
const refreshAccessToken = async () => {
  if (!refreshToken) {
    throw new Error("No refresh token available.");
  }

  try {
    const response = await instance.post("api/v1/auth/refresh", {
      refreshToken,
      clientId: "connect-infinity",
    });

    const { access_token, refresh_token } = response.data;

    // Store new tokens
    localStorage.setItem("callCenterToken", access_token);
    localStorage.setItem("refresh_token", refresh_token);

    return access_token;
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
};

// Interceptor for handling request
instance.interceptors.request.use(
  async (req) => {
    let token = localStorage.getItem("callCenterToken");

    // Check if token is expired and refresh if needed
    if (token && isTokenExpired(token)) {
      try {
        token = await refreshAccessToken();
      } catch (error) {
        console.error("Token refresh failed. Logging out.");
        logout();
        return Promise.reject(error);
      }
    }

    let urls = req.url.split("/");
    const endpoint = urls[urls.length - 1];

    // Add Authorization header if token exists and endpoint is not login
    if (token != null && endpoint !== "login") {
      req.headers.Authorization = `Bearer ${token}`;
    }

    // Handle loading state
    if (!req?.runInBackground && !req?.data?.runInBackground) {
      store.dispatch({ type: "ACTIVATE_LOADING" });
    } else {
      store.dispatch({ type: "INCREASE_LOADING_COUNT" });
    }

    // Clean params for GET requests
    if (req.params) {
      req.params = removeEmptyFields(req.params);
    }

    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// Response interceptor to handle token expiration (401 errors)
instance.interceptors.response.use(
  (response) => response, // Pass successful responses through
  async (error) => {
    const originalRequest = error.config;

    // If unauthorized (401) and no retry has been attempted yet
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        const newToken = await refreshAccessToken();

        // Set the new token in the original request
        originalRequest.headers.Authorization = `Bearer ${newToken}`;

        // Retry the original request
        return instance(originalRequest);
      } catch (refreshError) {
        console.error("Failed to refresh token:", refreshError);
        // If the refresh fails, logout and redirect to login
        logout();
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

// Logout function to clear localStorage and redirect to login

// For chatbot url and instance
const chatbotUrl = process.env.REACT_APP_CHATBOT_URL;

export const chatinstance = axios.create({
  baseURL: chatbotUrl,
  timeout: 30000,
});

// Add Authorization header for chatbot requests
chatinstance.interceptors.request.use((config) => {
  let token = localStorage.getItem("callCenterToken");

  if (token && isTokenExpired(token)) {
    return refreshAccessToken().then((newToken) => {
      config.headers.Authorization = `Bearer ${newToken}`;
      return config;
    });
  }

  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// For outbound campaign URL
const outboundCampaignURL =
  "https://connect-infinity-development.waterflowtechnology.net/";

export const outboundCampaign = axios.create({
  baseURL: outboundCampaignURL,
  timeout: 30000,
});

// For chitchat URL
const chatticketingurl = process.env.REACT_APP_CHAT_TICKETING_URL;

export const chatticketinginstance = axios.create({
  baseURL: chatticketingurl,
  timeout: 30000,
});

// Add Authorization header for chat ticketing requests
chatticketinginstance.interceptors.request.use((config) => {
  let token = localStorage.getItem("callCenterToken");

  if (token && isTokenExpired(token)) {
    return refreshAccessToken().then((newToken) => {
      config.headers.Authorization = `Bearer ${newToken}`;
      return config;
    });
  }

  config.headers.Authorization = `Bearer ${token}`;
  return config;
});
