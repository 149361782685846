import { backendSignin } from "app/services/authService";
import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

const Login = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");

    if (code) {
      handleAuthorizationCode(code);
    } else {
      redirectToKeycloak();
    }
  }, [location]);

  const redirectToKeycloak = () => {
    const keycloakUrl = process.env.REACT_APP_KEYCLOAK_AUTH_URL;
    const clientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
    const redirectUri = encodeURIComponent(
      process.env.REACT_APP_KEYCLOAK_REDIRECT_URI
    );
    const scope = "openid profile";
    const responseType = "code";

    const authUrl = `${keycloakUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}`;
    window.location.href = authUrl;
  };
  const handleAuthorizationCode = async (code) => {
    try {
      await backendSignin(code);
      history.push("/app");
    } catch (error) {
      console.error("Error during authentication:", error);
    }
  };

  return (
    <div>
      <h1>Logging in...</h1>
      {/* You can add a loading spinner or message here */}
    </div>
  );
};

export default Login;
