import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { Download } from "@mui/icons-material";
import dayjs from "dayjs";
import HoldTimeTable from "./HoldTimeTable";
import CommonReportFilter from "app/pages/AdminDashboard/CommonReportFilter";
import { useQuery } from "@tanstack/react-query";
import { instance } from "app/utils/axiosRequest";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import {
  adminHoldTimeReport,
  adminQueueReport,
  allQueueList,
} from "app/utils/api-urls/AdminAPiUrls";
import qs from "qs";
import { Bar, Line } from "react-chartjs-2";
import { API_ENDPOINTS } from "app/utils/api-urls/allUrls";

const QueueReport = () => {
  const [queue, setqueueData] = useState([]);

  const [filterData, setFilterData] = useState({
    queue: "",
    startDate: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });

  const { data, isLoading, error, refetch, isRefetching } = useQuery(
    ["admin-Hold Time"],
    () =>
      instance.get(
        API_ENDPOINTS.QUEUE_LOG.GET_STATS + "?" + qs.stringify(filterData)
      )
  );

  const convertSecondsToMinutesAndSeconds = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return { minutes, seconds: remainingSeconds };
  };

  const bargraphdata = {
    barChartData: {
      labels: ["Calls"], // We have a single label for all datasets
      datasets: [
        {
          label: "Entered", // Legend for the first dataset
          backgroundColor: ["#79A3B1"],
          borderWidth: 1,
          data: [data?.data?.entered],
        },
        {
          label: "Abandoned", // Legend for the second dataset
          backgroundColor: ["#EF9F9F"],
          borderWidth: 1,
          data: [data?.data?.abandoned],
        },
        {
          label: "Answered", // Legend for the third dataset
          backgroundColor: ["#C7E9B0"],
          borderWidth: 1,
          data: [data?.data?.answered],
        },
      ],
    },
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 500,
          max: 5000,
          callback: function (value) {
            return value;
          },
        },
      },
    },
  };

  const {
    data: data1,
    isLoading: isLoading1,
    error: error1,
    refetch: reftech1,
    isRefetching: isRefetching1,
  } = useQuery(["admin-all-queue-list"], () =>
    instance.get(API_ENDPOINTS.QUEUE.GET_ALL)
  );

  useEffect(() => {
    if (data1?.data) {
      setqueueData(
        data1?.data?.data?.map((item) => {
          return {
            id: item?._id,
            value: item?.number,
            label: item?.name,
          };
        })
      );
    }
  }, [data1?.data]);

  const handleClear = () => {
    setFilterData({
      ...filterData,
      queue: "",
      from: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
      to: dayjs().format("YYYY-MM-DD"),
    });
  };

  const handleChange = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFromDate = (value) => {
    setFilterData({
      ...filterData,
      startDate: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleToDate = (value) => {
    setFilterData({
      ...filterData,
      endDate: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleFilter = () => {
    refetch();
  };

  return (
    <>
      {/* <Box display="flex" alignItems="center" justifyContent="center">
    <img
      alt="Comming Soon"
      src="/comming-soon.jpg"
      style={{
        height: "90vh",
        width: "90vw",
      }}
    />
  </Box> */}
      <FullpageLoader open={isLoading} />
      <Box display="flex" gap="10px" width="100%" marginTop="1em">
        <Card sx={{ width: "25%" }}>
          <CardContent>
            <CommonReportFilter
              reportType="HoldTimeReport"
              handleClear={handleClear}
              handleFilter={handleFilter}
              handleFromDate={handleFromDate}
              handleToDate={handleToDate}
              QueueCategoryOptions={queue}
              filterData={filterData}
              handleChange={handleChange}
              isLoading={isRefetching}
            />
          </CardContent>
        </Card>
        <Box width="75%" display="flex" flexDirection="column" gap="10px">
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Card>
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box display="flex" flexDirection="column" gap="7px">
                    <Typography
                      variant="span"
                      fontSize="26px"
                      textAlign="center"
                      color="#2C74B3"
                    >
                      {data?.data?.entered}
                    </Typography>
                    <Typography
                      variant="span"
                      textAlign="center"
                      fontSize="20px"
                    >
                      Total Entered Calls
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} md={4}>
              <Card>
                <CardContent
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box display="flex" flexDirection="column" gap="7px">
                    <Typography
                      variant="span"
                      fontSize="26px"
                      textAlign="center"
                      color="#FF0000"
                    >
                      {data?.data?.abandoned}
                    </Typography>
                    <Typography variant="span" fontSize="20px">
                      Total Abandoned Calls
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} md={4}>
              <Card>
                <CardContent
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box display="flex" flexDirection="column" gap="7px">
                    <Typography
                      variant="span"
                      fontSize="26px"
                      textAlign="center"
                      color="#4E9F3D"
                    >
                      {data?.data?.answered}
                    </Typography>
                    <Typography variant="span" fontSize="20px">
                      Total Answered Calls
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            {/* <Grid item xs={6} md={3}>
              <Card>
                <CardContent
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box display="flex" flexDirection="column" gap="7px">
                    <Typography
                      variant="span"
                      fontSize="20px"
                      textAlign="center"
                      color="#071952"
                    >
                      {data?.data?.average_hold_time && (
                        <>
                          {`${
                            convertSecondsToMinutesAndSeconds(
                              data?.data?.average_hold_time
                            ).minutes
                          } min ${
                            convertSecondsToMinutesAndSeconds(
                              data?.data?.average_hold_time
                            ).seconds
                          } sec`}
                        </>
                      )}
                    </Typography>
                    <Typography variant="span" fontSize="14px">
                      Average Hold Time
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid> */}
            {/* maximum Hold Time */}
            {/* <Grid item xs={6} md={2.4}>
              <Card>
                <CardContent
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box display="flex" flexDirection="column" gap="7px">
                    <Typography
                      variant="span"
                      fontSize="20px"
                      textAlign="center"
                      color="#922C40"
                    >
                      {data?.data?.max_hold_time && (
                        <>
                          {`${
                            convertSecondsToMinutesAndSeconds(
                              data?.data?.max_hold_time
                            ).minutes
                          } min ${
                            convertSecondsToMinutesAndSeconds(
                              data?.data?.max_hold_time
                            ).seconds
                          } sec`}
                        </>
                      )}
                    </Typography>
                    <Typography
                      variant="span"
                      textAlign="center"
                      fontSize="14px"
                    >
                      Maximum Hold Time
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid> */}
          </Grid>

          <Box marginTop="1rem" width="100%">
            <Card height="auto">
              <CardContent>
                <Box width="100%" height="50vh">
                  <Bar data={bargraphdata?.barChartData} options={options} />
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default QueueReport;
