import { instance } from "app/utils/axiosRequest";

export const isAuthenticated = () => {
  return !!localStorage.getItem("callCenterToken");
};

export const logout = async () => {
  const keycloakLogoutUrl = process.env.REACT_APP_KEYCLOAK_LOGOUT;
  const redirectUri = process.env.REACT_APP_KEYCLOAK_REDIRECT_URI;
  const encodedRedirectUri = encodeURIComponent(redirectUri);
  const idToken = localStorage.getItem("id_token");
  localStorage.clear();
  try {
    window.location.href = `${keycloakLogoutUrl}?post_logout_redirect_uri=${encodedRedirectUri}&id_token_hint=${idToken}`;
  } catch (error) {
    console.error("Logout failed:", error);
  }
};

export const backendSignin = async (authorizationCode) => {
  try {
    const response = await instance.post("api/v1/auth/sign-in", {
      authorizationCode,
      redirectUrl: window.location.origin,
      clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    });

    const { data } = response;

    localStorage.setItem("callCenterToken", data.access_token);
    localStorage.setItem("username", data.firstName);
    localStorage.setItem("refresh_token", data.refresh_token);
    localStorage.setItem("id_token", data.id_token);
    localStorage.setItem("callCenterUser", JSON.stringify(data.extension));
    localStorage.setItem("role", data.role);

    return data;
  } catch (error) {
    console.error("Failed to sign in with backend", error);
    throw error;
  }
};
