import { supervisorallAgent } from "app/utils/api-urls/SupervisorAPiUrls";
import { instance, socketURL } from "app/utils/axiosRequest";
import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import SupportAgent from "@mui/icons-material/SupportAgent";
import { useQuery } from "@tanstack/react-query";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import { PhoneInTalk, RingVolume } from "@mui/icons-material";
import socketIOClient from "socket.io-client";
import { API_ENDPOINTS } from "app/utils/api-urls/allUrls";

const SupervisorAgent = () => {
  const [supervisorAgentData, setsupervisorAgentData] = useState();
  const [socketData, setSocketData] = useState();

  const { data, isLoading } = useQuery(["supervisor-agent-data"], () =>
    instance.get(API_ENDPOINTS.USER.GET_AGENT)
  );

  useEffect(() => {
    data && setsupervisorAgentData(data?.data);
  }, [data]);

  useEffect(() => {
    const accessToken = localStorage.getItem("callCenterToken");
    const socket = socketIOClient(socketURL.liveUrl, {
      extraHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    socket.on("extension-status", (newItems) => {
      setSocketData(newItems);
    });
  }, []);

  useEffect(() => {
    if (socketData) {
      const newState = supervisorAgentData?.map((agentObj) => {
        if (agentObj.extension === socketData.extension) {
          return {
            firstName: agentObj.firstName,
            lastName: agentObj.lastName,
            extension: agentObj.extension,
            sipStatus: socketData.status,
          };
        }
        return agentObj;
      });
      setsupervisorAgentData(newState);
    }
  }, [socketData]);

  return (
    <>
      <FullpageLoader open={isLoading} />
      <Box marginTop="1em">
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              {supervisorAgentData?.map((data) => {
                const data1 = data?.sipStatus;
                return (
                  <Grid item xs={6} md={2}>
                    <Box>
                      <Card>
                        <CardContent>
                          <Box
                            display="flex"
                            flexWrap="wrap"
                            flexDirection="column"
                            gap="12px"
                          >
                            <Box
                              display="flex"
                              flexWrap="wrap"
                              flexDirection="row"
                              gap="4px"
                            >
                              {data?.sipStatus === "Unavailable" ? (
                                <SupportAgent
                                  sx={{ color: "#FF0000", fontSize: "21px" }}
                                />
                              ) : data?.sipStatus === "Idle" ? (
                                <SupportAgent
                                  sx={{ color: "#379237", fontSize: "21px" }}
                                />
                              ) : data?.sipStatus === "InUse" ? (
                                <PhoneInTalk
                                  sx={{ color: "#0000FF", fontSize: "21px" }}
                                />
                              ) : data?.sipStatus === "Ringing" ? (
                                <RingVolume
                                  sx={{ color: "#0000FF", fontSize: "21px" }}
                                />
                              ) : (
                                <SupportAgent
                                  sx={{ color: "#293462", fontSize: "21px" }}
                                />
                              )}

                              <Typography
                                variant="span"
                                fontStyle="italic"
                                fontSize="16px"
                              >
                                {data?.firstName}
                              </Typography>
                              <Typography
                                variant="span"
                                fontStyle="italic"
                                fontSize="16px"
                              >
                                {data?.lastName}
                              </Typography>
                              <Typography
                                variant="span"
                                fontStyle="italic"
                                fontSize="16px"
                              >
                                ({data?.extension})
                              </Typography>
                            </Box>
                            <Box
                              display="flex"
                              flexWrap="wrap"
                              flexDirection="rows"
                              gap="10px"
                            >
                              <Typography
                                variant="span"
                                fontStyle="italic"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                Status:
                              </Typography>
                              <Typography
                                variant="span"
                                fontStyle="italic"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                {data.sipStatus}
                              </Typography>
                            </Box>
                            {data?.isInBreak === true && (
                              <Typography
                                variant="span"
                                fontStyle="italic"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                BreakReason:{data?.breakReason}
                              </Typography>
                            )}
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default SupervisorAgent;
