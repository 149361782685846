import {
  Person,
  Badge,
  PendingActions,
  CheckCircle,
  Grid3x3,
  Phone,
  PlayCircleFilledWhite,
  FileOpenOutlined,
  Visibility,
  Email,
  ContactPage,
} from "@mui/icons-material";
import {
  Modal,
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
  IconButton,
  Tooltip,
  CircularProgress,
  Grid,
  Chip,
  Divider,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import ReactAudioPlayer from "react-audio-player";
import {
  agentlistenRecording,
  agentlistenVoicemail,
} from "app/utils/api-urls/AgentApiUrls";
import { instance } from "app/utils/axiosRequest";
import {
  adminlistenRecording,
  adminlistenVoicemail,
} from "app/utils/api-urls/AdminAPiUrls";
import {
  supervisorlistenRecording,
  supervisorlistenVoicemail,
} from "app/utils/api-urls/SupervisorAPiUrls";
import ChatPreviewModal from "app/pages/dashboard/ChatViewModal";
import { API_ENDPOINTS } from "app/utils/api-urls/allUrls";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  maxHeight: 700,
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 5,
  borderRadius: 2,
};

const ViewTicketModal = ({ open, handleClose, data }) => {
  const userRole = localStorage.getItem("role");

  const [showPlayer, setShowPlayer] = useState(false);
  const [audioSrc, setAudioSrc] = useState(null);
  const [selectedRecordingId, setSelectedRecordingId] = useState(null);
  const [loadingAudio, setLoadingAudio] = useState(false);
  const [isChatModalOpen, setChatModalOpen] = useState(false);
  const [currentChat, setCurrentChat] = useState(null);

  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "",
  });

  const scrollableStyle = {
    maxHeight: "200px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "rgba(0,0,0,.05)",
    },
  };
  const lastRemarkRef = useRef(null);
  const remarksListContainerRef = useRef(null);

  useEffect(() => {
    if (open && data?.remarksList?.length > 0) {
      const timer = setTimeout(() => {
        if (lastRemarkRef.current) {
          remarksListContainerRef.current.scrollTop =
            lastRemarkRef.current.offsetTop;
        }
      }, 10);
      return () => clearTimeout(timer);
    }
  }, [open, data?.remarksList]);

  useEffect(() => {
    //to reset src after modal is not on
    if (!open) {
      setAudioSrc(null);
      setSelectedRecordingId(null);
      setShowPlayer(false);
    }
  }, [open]);

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };

  const handleAudioClick = async (recordingId) => {
    try {
      setLoadingAudio(true);

      if (selectedRecordingId === recordingId) {
        // If the clicked recording is already selected, reset the player
        setSelectedRecordingId(null);
        setAudioSrc(null);
        setShowPlayer(false);
      } else {
        // Use a single API endpoint with recordingId
        const apiUrl = `${API_ENDPOINTS.CALL_LOGS.GET_RECORDING}/${recordingId}`;

        const response = await instance.get(apiUrl, {
          responseType: "arraybuffer",
        });

        const blob = new Blob([response.data], { type: "audio/wav" });
        const audioUrl = URL.createObjectURL(blob);

        setSelectedRecordingId(recordingId);
        setAudioSrc(audioUrl);
        setShowPlayer(true); // Show the player
      }
    } catch (error) {
      setSnackBar({
        open: true,
        message: "Error fetching audio recording.",
      });
    } finally {
      setLoadingAudio(false);
    }
  };

  const handleVoiceMailAudioClick = async (recordingId) => {
    try {
      setLoadingAudio(true);
      if (selectedRecordingId === recordingId) {
        setSelectedRecordingId(null);
        setAudioSrc(null);
        setShowPlayer(false);
      } else {
        const adminApiURL = `${adminlistenVoicemail.admin_voicemail_recording}/${recordingId}`;
        const agentApiURL = `${agentlistenVoicemail.agent_voicemail_recording}/${recordingId}`;
        const supervisorApiUrl = `${supervisorlistenVoicemail.supervisor_voicemail_recording}/${recordingId}`;
        const apiUrl =
          userRole === "admin"
            ? adminApiURL
            : userRole === "agent"
            ? agentApiURL
            : userRole === "supervisor"
            ? supervisorApiUrl
            : null;

        const response = await instance.get(apiUrl, {
          responseType: "arraybuffer",
        });

        const blob = new Blob([response.data], { type: "audio/wav" });
        const audioUrl = URL.createObjectURL(blob);

        setSelectedRecordingId(recordingId);
        setAudioSrc(audioUrl);
        setShowPlayer(true); // Show player
      }
      setLoadingAudio(false);
    } catch (error) {
      setLoadingAudio(false);
      setSnackBar({
        open: true,
        message: "Error fetching audio recording.",
      });
    }
  };

  const handleChatPreviewClick = (chat) => {
    setCurrentChat(chat);
    setChatModalOpen(true);
  };

  return (
    <>
      <ChatPreviewModal
        isOpen={isChatModalOpen}
        onClose={() => {
          setChatModalOpen(false);
          setCurrentChat(null);
        }}
        chat={currentChat}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2" sx={{ marginBottom: 2 }}>
            Ticket Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
                gap="20px"
              >
                <Avatar
                  src={`https:kyc.naasasecurities.com.np/${data?.clientImage}`}
                  alt={data?.clientName}
                  sx={{ width: 90, height: 90 }}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  gap="10px"
                >
                  <Box display="flex" alignItems="center" gap="5px">
                    <Person />
                    <Typography variant="span">{data?.clientName}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap="5px">
                    <Badge />
                    <Typography variant="span">{data?.clientId}</Typography>
                  </Box>

                  <Box display="flex" alignItems="center" gap="5px">
                    <Typography variant="span">KYC Status:</Typography>
                    <Typography variant="span">{data?.kycStatus}</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap="10px"
              >
                <Box display="flex" alignItems="center" gap="5px">
                  <Phone />
                  <Typography variant="span">{data?.clientPhone}</Typography>
                </Box>
                <Box display="flex" alignItems="center" gap="5px">
                  <Email />
                  <Typography variant="span">{data?.clientEmail}</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Card sx={{ marginTop: "1em" }}>
            <CardContent>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                color={
                  data?.status === "pending"
                    ? "#E7625F"
                    : data?.status === "open"
                    ? "#303481"
                    : "#81B622"
                }
                marginBottom="10px"
              >
                <Box display="flex" alignItems="center">
                  <Grid3x3 />
                  <Typography variant="span">{data?.ticketId}</Typography>
                </Box>
                {loadingAudio ? (
                  <CircularProgress />
                ) : showPlayer ? (
                  <ReactAudioPlayer
                    controls
                    src={audioSrc}
                    autoPlay
                    style={{ height: "40px" }}
                  />
                ) : null}
                {data?.type === "NORMAL" && (
                  <Tooltip
                    arrow
                    placement="top"
                    size="lg"
                    title="Play Call Recording"
                  >
                    <IconButton
                      onClick={() => handleAudioClick(data?._id)}
                      sx={{ color: "#213555" }}
                      aria-label="play"
                    >
                      <PlayCircleFilledWhite />
                    </IconButton>
                  </Tooltip>
                )}
                {data?.type === "VOICEMAIL" && (
                  <Tooltip
                    arrow
                    placement="top"
                    size="lg"
                    title="Play Voice Mail Recording"
                  >
                    <IconButton
                      onClick={() =>
                        handleVoiceMailAudioClick(data?.voicemailLogId)
                      }
                      sx={{ color: "#765827" }}
                      aria-label="play"
                    >
                      <PlayCircleFilledWhite />
                    </IconButton>
                  </Tooltip>
                )}
                {data?.type === "CHAT" && (
                  <Tooltip title="View Chat">
                    <IconButton
                      size="small"
                      onClick={() => handleChatPreviewClick(data?.chats)}
                    >
                      <Visibility sx={{ color: "#765827" }} fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              <Box display="flex" flexDirection="column" gap="1rem">
                <Box display="flex" width="100%" justifyContent="space-between">
                  <Box width="45%" display="flex" alignItems="center">
                    <Typography
                      fontSize="16px"
                      variant="span"
                      sx={{ marginRight: "10px", fontWeight: "bold" }}
                    >
                      Issue Category:
                    </Typography>
                    <Typography fontSize="16px" variant="span">
                      {data?.issue?.name}
                    </Typography>
                  </Box>

                  <Box width="45%" display="flex" alignItems="center">
                    <Typography
                      fontSize="16px"
                      variant="span"
                      sx={{ marginRight: "10px", fontWeight: "bold" }}
                    >
                      Sub Issue Category:
                    </Typography>
                    <Typography fontSize="16px" variant="span">
                      {data?.sub_issue?.name}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" width="100%" justifyContent="space-between">
                  <Box width="45%" display="flex" alignItems="center">
                    <Typography
                      fontSize="16px"
                      variant="span"
                      sx={{ marginRight: "10px", fontWeight: "bold" }}
                    >
                      Opened By :
                    </Typography>
                    <Typography fontSize="16px" variant="span">
                      {data?.openedBy}
                    </Typography>
                  </Box>

                  <Box
                    width="45%"
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    gap="10px"
                  >
                    <Typography
                      fontSize="16px"
                      variant="span"
                      sx={{ marginRight: "10px", fontWeight: "bold" }}
                    >
                      Assigned User:
                    </Typography>
                    <Typography fontSize="16px" variant="span">
                      {data?.assignedTo?.join(", ")}
                    </Typography>
                  </Box>
                </Box>{" "}
                <Box display="flex" width="100%" justifyContent="space-between">
                  <Box width="45%" display="flex" alignItems="center">
                    <Typography
                      fontSize="16px"
                      variant="span"
                      sx={{ marginRight: "10px", fontWeight: "bold" }}
                    >
                      Assigned Department:
                    </Typography>
                    <Typography fontSize="16px" variant="span">
                      {data?.assignedDepartment
                        ?.map((department) => department.name)
                        .join(", ")}
                    </Typography>
                  </Box>
                </Box>{" "}
              </Box>
            </CardContent>
          </Card>
          {data?.notificationMessages?.length > 0 && (
            <Card sx={{ marginTop: ".5rem" }}>
              <CardContent>
                <Typography fontWeight="bold">
                  Notification Message :{" "}
                </Typography>

                <Box sx={scrollableStyle}>
                  {data?.notificationMessages?.map((message, index) => (
                    <Box key={index}>
                      <Typography variant="body2">{message}</Typography>
                      <Divider sx={{ mt: "1rem", mb: "1rem" }} />
                      {index === data.notificationMessages.length - 1 && (
                        <Box display="flex" justifyContent="flex-end">
                          <Typography variant="caption">
                            {data?.notificationStatus}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </CardContent>
            </Card>
          )}
          <Card sx={{ marginTop: ".5rem" }}>
            <CardContent>
              <Typography fontWeight="bold">Remarks : </Typography>

              <Box sx={scrollableStyle} ref={remarksListContainerRef}>
                {data?.remarksList?.map((remarkItem, index) => (
                  <Box
                    key={index}
                    mb={1}
                    ref={
                      index === data?.remarksList?.length - 1
                        ? lastRemarkRef
                        : null
                    }
                  >
                    <Typography variant="body1">
                      {remarkItem.remarks}
                    </Typography>
                    <Box display="flex" justifyContent="flex-end">
                      <Typography variant="caption">
                        {remarkItem.updatedBy} (
                        {dayjs(remarkItem.updatedAt).format("YYYY-MM-DD HH:mm")}
                        )
                      </Typography>
                    </Box>
                    <Divider sx={{ mt: "1rem" }} />
                  </Box>
                ))}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </>
  );
};

export default ViewTicketModal;
