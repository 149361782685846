import React, { useState, useEffect } from "react";
import { Dropdown, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Trans } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import { routes } from "app/routes";
import {
  AssignmentTurnedIn,
  Cached,
  CallSplit,
  Comment,
  Create,
  FiberManualRecord,
  HeadsetMic,
  Notifications,
  NotificationsActive,
  NotificationsNone,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import AgentStatus from "./AgentStatus";
import BreakStatus from "app/components/Breakbutton";
import { instance, socketURL } from "app/utils/axiosRequest";
import { useMutation, useQuery } from "@tanstack/react-query";
import WebRtcStatus from "./WebRtcStatus";
import Myprofile from "app/pages/AgentProfile";
import qs from "qs";
import {
  agentGetallNotifications,
  agentReadNotifications,
  breakButton,
} from "app/utils/api-urls/AgentApiUrls";
import socketIOClient from "socket.io-client";
import { API_ENDPOINTS } from "app/utils/api-urls/allUrls";
import { logout } from "app/services/authService";
import {
  SupervisorbreakButton,
  supervisorGetallNotifications,
  supervisorReadNotifications,
} from "app/utils/api-urls/SupervisorAPiUrls";
import {
  adminGetallNotifications,
  adminReadNotifications,
  breakCategory,
} from "app/utils/api-urls/AdminAPiUrls";

const Topbar = (props) => {
  const history = useHistory();

  const userRole = localStorage.getItem("role");

  const { profile } = useSelector((state) => state.authReducer);
  const [modalView, setModalView] = React.useState(false);
  const [profileModalView, setProfileModalView] = React.useState(false);

  const username = localStorage.getItem("username");
  const userExtension = JSON.parse(localStorage.getItem("callCenterUser"));
  const SipStatiusdata = localStorage.getItem("SipStatus");
  const [statusbutton, setStatusbutton] = useState({
    is_in_break: false,
    reason: "",
  });

  const redirectToTicket = () => history.push("/app/ticket/create-ticket-new");
  const checkRole = () => {
    const DB = window.localStorage;
    const role = DB.getItem("role");
    return role ?? "null";
  };
  const handleNotificationClick = (redirectUrl, event) => {
    console.log(`Redirecting to: ${redirectUrl}`);
    event?.stopPropagation();
    history.push(`/${redirectUrl}`);
  };

  const roleBasedUrls = {
    agent: breakButton.extension_status,
    admin: breakCategory.break_category,
    supervisor: SupervisorbreakButton.extension_status,
  };

  const extensionStatusUrl = API_ENDPOINTS.USER.GET_EXTENSION_STATUS;

  const {
    data: data1,
    refetch: refetch1,
    fetch: fetch1,
    isRefetching: isRefetching1,
  } = useQuery(["extensionStatus"], () => instance.get(extensionStatusUrl));

  const [notificationfiltersData, setNotificationFiltersData] = useState({
    page: 1,
    limit: 20,
  });

  useEffect(() => {
    const accessToken = localStorage.getItem("callCenterToken");
    const socket = socketIOClient(socketURL.liveUrl, {
      extraHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    socket.on(`extension-status@${userExtension}`, (data) => {
      if (data) {
        setStatusbutton(data);
      }
    });
    return () => socket.disconnect();
  }, []);

  const handleBreakStatusChange = (event) => {
    setStatusbutton({
      ...statusbutton,
      [event.target.name]: event.target.value,
    });
  };

  const handleapihit = () => {
    refetch1();
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleNotificationsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setAnchorEl(null);
  };

  const [notifications, setNotifications] = useState([]);

  const getIconForNotification = (contentBody) => {
    if (contentBody.toLowerCase().includes("assigned")) {
      return <CallSplit sx={{ color: "#232C93", mr: 1 }} />;
    } else if (contentBody.toLowerCase().includes("created")) {
      return <Create sx={{ color: "#232C93", mr: 1 }} />;
    } else if (contentBody.toLowerCase().includes("comment")) {
      return <Comment sx={{ color: "#232C93", mr: 1 }} />;
    } else {
      return null;
    }
  };

  const roleBasedApiUrls = {
    agent: {
      getallnotification: agentGetallNotifications.agent_get_all_notifications,
      readnotification: agentReadNotifications.agent_read_notification,
    },
    admin: {
      getallnotification: adminGetallNotifications.admin_get_all_notifications,
      readnotification: adminReadNotifications.admin_read_notification,
    },
    supervisor: {
      getallnotification:
        supervisorGetallNotifications.supervisor_get_all_notifications,
      readnotification:
        supervisorReadNotifications.supervisor_read_notification,
    },
  };

  const { data: notificationsData, refetch } = useQuery(
    ["role-based-get-all-notification"],
    () =>
      instance.get(
        `${roleBasedApiUrls[userRole]?.getallnotification}?${qs.stringify(
          notificationfiltersData
        )}`
      )
  );

  useEffect(() => {
    setNotifications(notificationsData?.data?.data || []);
  }, [notificationsData?.data?.data]);

  const { mutate: markAllAsRead, isLoading: isMarkingAllRead } = useMutation({
    mutationFn: () =>
      instance.patch(roleBasedApiUrls[userRole]?.readnotification),
    onSuccess: () => {
      refetch();
    },
  });

  const handleMarkAllAsRead = () => {
    markAllAsRead();
  };
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        fixed="top"
        className="default-layout-navbar"
      >
        <Navbar.Brand>
          <Link
            className="navbar-brand brand-logo-mini"
            style={{ maxWidth: 40 }}
            to="/app/dashboard"
          >
            <Box display="flex" gap="10px" alignItems="center" color="black">
              <HeadsetMic sx={{ color: "#232C93" }} />
              Connect Infinity
              <Box
                fontSize="14px"
                color="#232C93"
                border="1px solid #232C93"
                borderRadius="4px"
                padding="2px"
                display="flex"
              >
                1.0.2
              </Box>
            </Box>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          style={{ backgroundColor: "#232C93" }}
          aria-controls="responsive-navbar-nav"
        />

        <Navbar.Collapse>
          <Nav className="mr-auto">
            {routes.map((e, index) => {
              if (e.hideInSidebar) return null;
              if (e.dropDownState) {
                return (
                  <>
                    {e.shortPath === "/admin-dashboard" ||
                    e.shortPath === "/settings" ||
                    e.shortPath === "/report" ||
                    e.shortPath === "/outbound-campaign" ||
                    e.shortPath === "/ticket" ? (
                      e.auth.includes(checkRole()) ? (
                        <NavDropdown
                          key={index}
                          title={
                            <span>
                              <i
                                className={`mdi mdi-${e.icon} mr-1 menu-icon `}
                                style={{ color: "#232C93" }}
                              ></i>
                              {e.name}
                            </span>
                          }
                          id="collasible-nav-dropdown"
                        >
                          {e.dropDownMenu?.map((e, index) => {
                            if (e.hideInSidebar) return null;

                            return (
                              <>
                                {e.shortPath === "/ticket/assigned-to-me" ? (
                                  e?.auth?.includes(checkRole()) ? (
                                    <NavDropdown.Item
                                      key={index}
                                      className="py-2"
                                    >
                                      <Link
                                        to={e.path}
                                        className="link-no-color"
                                      >
                                        <i
                                          className={`mdi mdi-${e.icon} mr-2 menu-icon text-orange`}
                                        ></i>
                                        {e.name}
                                      </Link>
                                    </NavDropdown.Item>
                                  ) : null
                                ) : (
                                  <NavDropdown.Item
                                    key={index}
                                    className="py-2"
                                  >
                                    <Link to={e.path} className="link-no-color">
                                      <i
                                        className={`mdi mdi-${e.icon} mr-2 menu-icon text-orange`}
                                      ></i>
                                      {e.name}
                                    </Link>
                                  </NavDropdown.Item>
                                )}
                              </>
                            );
                          })}
                        </NavDropdown>
                      ) : null
                    ) : (
                      <NavDropdown
                        key={index}
                        title={
                          <span>
                            <i
                              style={{ color: "#232C93" }}
                              className={`mdi mdi-${e.icon} mr-1 menu-icon `}
                            ></i>
                            {e.name}
                          </span>
                        }
                        id="collasible-nav-dropdown"
                      >
                        {e.dropDownMenu?.map((e, index) => {
                          if (e.hideInSidebar) return null;
                          return (
                            <NavDropdown.Item key={index} className="py-2">
                              <Link to={e.path} className="link-no-color">
                                <i
                                  className={`mdi mdi-${e.icon} mr-2 menu-icon text-orange`}
                                ></i>
                                {e.name}
                              </Link>
                              {e?.dropDownMenuChilds?.map((a, i) => (
                                <NavDropdown.Item key={i} className="py-2">
                                  <Link to={a.path} className="link-no-color">
                                    <i
                                      className={`mdi mdi-${a.icon} mr-2 menu-icon text-orange`}
                                    ></i>
                                    {a.name}sss
                                  </Link>
                                </NavDropdown.Item>
                              ))}
                            </NavDropdown.Item>
                          );
                        })}
                      </NavDropdown>
                    )}
                  </>
                );
              }
              if (e.auth.includes(checkRole())) {
                return (
                  <Nav.Link href={e.path} key={index}>
                    <i
                      className={`mdi mdi-${e.icon} menu-icon  mr-1`}
                      style={{ color: "#232C93" }}
                    ></i>
                    <span className="menu-title ">{e.name}</span>
                  </Nav.Link>
                );
              }
            })}
          </Nav>

          <Nav.Link style={{ position: "relative" }}>
            <span className=" current-time"></span>
          </Nav.Link>

          <WebRtcStatus />
          {(userRole === "agent" || userRole === "supervisor") && (
            <Box display="flex" flexDirection="row" gap="10px">
              <Tooltip title="Press ! if the break button is not working properly">
                <Cached onClick={handleapihit} sx={{ cursor: "pointer" }} />
              </Tooltip>

              <BreakStatus
                statusbutton={statusbutton}
                handleBreakStatusChange={handleBreakStatusChange}
              />
            </Box>
          )}

          {/* <IconButton
            aria-label="Notification"
            onClick={handleNotificationsClick}
          >
            <Notifications sx={{ color: "#232C93" }} />
          </IconButton> */}

          <Menu
            id="notification-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleNotificationsClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              "& .MuiPaper-root": {
                maxWidth: 360,
                maxHeight: "80vh",
              },
              "& .MuiMenuItem-root": {
                backgroundColor: "inherit", // Default background color
                "&:hover": {
                  backgroundColor: "#f4f4f4", // Lighter color on hover
                },
                "&.Mui-selected, &.Mui-selected:hover": {
                  backgroundColor: "#e0f7fa", // Lighter color for selected / active items
                },
              },
              "& .unread": {
                backgroundColor: "#e0f7fa",
                "&:hover": {
                  backgroundColor: "#b2ebf2", // Slightly darker on hover for unread notifications
                },
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Notifications
              </Typography>
              <Button size="small" onClick={handleMarkAllAsRead}>
                Mark All as Read
              </Button>
            </Box>
            <Divider />
            {notifications?.map((notification, index) => (
              <MenuItem
                key={notification?._id}
                onClick={() => {
                  handleNotificationsClose();
                }}
                sx={{
                  backgroundColor: notification?.read ? "inherit" : "#e0f7fa",
                }}
                className={notification?.read ? "" : "unread"}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                  onClick={() =>
                    handleNotificationClick(notification?.redirectUrl)
                  }
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "4px",
                    }}
                  >
                    {getIconForNotification(notification?.content_body)}
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold", flexGrow: 1 }}
                    >
                      {notification.sender.firstName}{" "}
                      {notification.sender.lastName}
                    </Typography>
                    {!notification.read && (
                      <FiberManualRecord
                        sx={{ fontSize: "10px", color: "#f44336" }}
                      /> // Red dot for unread notifications
                    )}
                  </Box>

                  <Typography
                    variant="body2"
                    sx={{
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                      hyphens: "auto",
                    }}
                  >
                    {notification.content_body}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                      mt: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ fontSize: "0.75rem" }}
                    >
                      {new Date(notification.createdAt).toLocaleString()}
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
            ))}
            {notifications.length === 0 && (
              <MenuItem disabled sx={{ justifyContent: "center" }}>
                No notifications
              </MenuItem>
            )}
          </Menu>

          {/* <AgentStatus userExtension={userExtension} /> */}

          <Dropdown alignRight>
            <Dropdown.Toggle
              className="nav-link btn-theme"
              style={{
                boxShadow: "none",
                color: "#232C93",
                display: "flex",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <div
                className="nav-profile-img"
                style={{
                  display: "flex",
                  gap: "5px",
                }}
              >
                <span>{username}</span>
                <i className={`mdi mdi-account menu-icon`}></i>
                <span className="availability-status online"></span>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="navbar-dropdown">
              {/* <Dropdown.Item
                href="#"
                onClick={(evt) => {
                  evt.preventDefault();
                  redirectToProfile();
                }}
              >
                <i className="mdi mdi-account mr-2 text-primary"></i>
                <Trans>My Profile</Trans>
              </Dropdown.Item> */}
              {/* <Dropdown.Item
                href="#"
                onClick={(evt) => {
                  setModalView(true);
                  evt.preventDefault();
                }}
              >
                <i className="mdi mdi-key-variant mr-2 text-primary"></i>
                <Trans>Change Password</Trans>
              </Dropdown.Item> */}
              <Dropdown.Item
                href=""
                onClick={(evt) => {
                  evt.preventDefault();
                  logout();
                }}
              >
                <i className="mdi mdi-logout mr-2 text-primary"></i>
                <Trans>LogOut</Trans>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <button id="redirectToTicket" onClick={redirectToTicket} hidden>
            Redirect
          </button>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Topbar;
