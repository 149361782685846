import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import SupportAgent from "@mui/icons-material/SupportAgent";
import { useLocation } from "react-router-dom";
import { socketURL } from "app/utils/axiosRequest";
import axios from "axios";
import socketIOClient from "socket.io-client";
import { useQuery } from "@tanstack/react-query";
import { instance } from "app/utils/axiosRequest";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import { extensionList } from "app/utils/api-urls/AdminAPiUrls";
import { Call, PhoneInTalk, RingVolume, Search } from "@mui/icons-material";
import qs from "qs";
import { API_ENDPOINTS } from "app/utils/api-urls/allUrls";

const TotalLiveAgent = () => {
  const [agentData, setAgentData] = useState();
  const [socketData, setSocketData] = useState();

  const [extensionFilterData, setExtensionFilterData] = useState({
    role: "agent",
    extension: "",
  });

  const { data, isLoading } = useQuery(
    ["agent-data", extensionFilterData],
    () =>
      instance.get(
        API_ENDPOINTS.USER.GET_ALL + "?" + qs.stringify(extensionFilterData)
      ),
    {
      enabled: true,
    }
  );

  useEffect(() => {
    data && setAgentData(data?.data?.data);
  }, [data]);

  useEffect(() => {
    const accessToken = localStorage.getItem("callCenterToken");
    const socket = socketIOClient(socketURL.liveUrl, {
      extraHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    socket.on("extension-status", (newItems) => {
      setSocketData(newItems);
    });
  }, []);

  useEffect(() => {
    if (socketData) {
      const newState = agentData?.map((agentObj) => {
        if (agentObj.extension === socketData.extension) {
          return {
            firstName: agentObj.firstName,
            lastName: agentObj.lastName,
            extension: agentObj.extension,
            sipStatus: socketData.status,
            reason: socketData.reason,
          };
        }
        return agentObj;
      });
      setAgentData(newState);
    }
  }, [socketData]);

  return (
    <>
      <FullpageLoader open={isLoading} />
      <Box marginTop="1em">
        <Box padding={2} display="flex" gap="10px">
          <TextField
            label="Search by Extension"
            variant="outlined"
            size="small"
            value={extensionFilterData.extension}
            onChange={(e) =>
              setExtensionFilterData({
                ...extensionFilterData,
                extension: e.target.value,
              })
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              {agentData?.map((data) => {
                const data1 = data?.sipStatus;
                return (
                  <Grid item xs={6} md={2}>
                    <Box
                    // "3px solid #285430"
                    >
                      <Card>
                        <CardContent>
                          <Box
                            display="flex"
                            flexWrap="wrap"
                            flexDirection="column"
                            gap="12px"
                          >
                            <Box
                              display="flex"
                              flexWrap="wrap"
                              flexDirection="row"
                              gap="4px"
                            >
                              {data?.sipStatus === "Unavailable" ? (
                                <SupportAgent
                                  sx={{ color: "#FF0000", fontSize: "21px" }}
                                />
                              ) : data?.sipStatus === "Idle" ? (
                                <SupportAgent
                                  sx={{ color: "#379237", fontSize: "21px" }}
                                />
                              ) : data?.sipStatus === "InUse" ? (
                                <PhoneInTalk
                                  sx={{ color: "#0000FF", fontSize: "21px" }}
                                />
                              ) : data?.sipStatus === "is-in-break" ? (
                                <SupportAgent
                                  sx={{ color: "#F3B95F", fontSize: "21px" }}
                                />
                              ) : data?.sipStatus === "Ringing" ? (
                                <RingVolume
                                  sx={{ color: "#0000FF", fontSize: "21px" }}
                                />
                              ) : (
                                <SupportAgent
                                  sx={{ color: "#293462", fontSize: "21px" }}
                                />
                              )}

                              <Typography
                                variant="span"
                                fontStyle="italic"
                                fontSize="16px"
                              >
                                {data?.firstName}
                              </Typography>
                              <Typography
                                variant="span"
                                fontStyle="italic"
                                fontSize="16px"
                              >
                                {data?.lastName}
                              </Typography>
                              <Typography
                                variant="span"
                                fontStyle="italic"
                                fontSize="16px"
                              >
                                ({data?.extension})
                              </Typography>
                            </Box>
                            <Box
                              display="flex"
                              flexWrap="wrap"
                              flexDirection="rows"
                              gap="10px"
                            >
                              <Typography
                                variant="span"
                                fontStyle="italic"
                                fontWeight="bold"
                                fontSize="12px"
                              >
                                Status:
                              </Typography>
                              <Typography
                                variant="span"
                                fontStyle="italic"
                                fontSize="13px"
                              >
                                {data.sipStatus === "is-in-break"
                                  ? data.reason
                                  : data.sipStatus}
                              </Typography>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default TotalLiveAgent;
