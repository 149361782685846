import React, { useState, useEffect } from "react";
import { Alert, Box, Button, Snackbar, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { instance, socketURL } from "app/utils/axiosRequest";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import BreakModal from "./popupmodal";
import BreakEndModal from "./EndBreakModal";
import CircleIcon from "@mui/icons-material/Circle";
import HourglassDisabledIcon from "@mui/icons-material/HourglassDisabled";
import { breakButton } from "app/utils/api-urls/AgentApiUrls";
import socketIOClient from "socket.io-client";
import { SupervisorbreakButton } from "app/utils/api-urls/SupervisorAPiUrls";
import { breakCategory } from "app/utils/api-urls/AdminAPiUrls";
import { API_ENDPOINTS } from "app/utils/api-urls/allUrls";

const BreakStatus = ({ statusbutton, handleBreakStatusChange }) => {
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const [modaldata, setModaldata] = useState({
    open: false,
  });

  const [modalenddata, setModalenddata] = useState({
    open: false,
  });

  const startBreakMutation = useMutation(
    ({ isEndingBreak, ...payload }) => {
      return instance.patch(API_ENDPOINTS.USER.USER_BREAK, payload);
    },
    {
      onSuccess: (_, variables) => {
        const { isEndingBreak } = variables;
        if (isEndingBreak) {
          setModalenddata({ open: false });
          setSnackBar({
            open: true,
            severity: "success",
            message: "Break ended successfully",
          });
        } else {
          setModaldata({ open: false });
          setSnackBar({
            open: true,
            severity: "success",
            message: "Break started successfully",
          });
        }
      },
      onError: (error) => {
        console.error("Error handling break", error);
        setSnackBar({
          open: true,
          severity: "error",
          message: "Error handling break",
        });
      },
    }
  );

  const handlestartbreaksubmit = () => {
    startBreakMutation.mutate({
      isEndingBreak: false,
      reason: statusbutton?.reason,
      paused: true,
    });
  };

  const handledbuttonsubmit = () => {
    startBreakMutation.mutate({
      isEndingBreak: true,
      paused: false,
    });
  };

  const HandleOpenModel = () => {
    setModaldata({
      open: true,
    });
  };

  const HandleOpenEndModel = () => {
    setModalenddata({
      open: true,
    });
  };

  const handlemodalClose = () => {
    setModaldata({
      open: false,
    });
  };

  const handleendmodalClose = () => {
    setModalenddata({
      open: false,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };

  return (
    <Box display="flex" gap="10px">
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackBar.severity}
          sx={{ width: "100%" }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>

      <BreakModal
        open={modaldata.open}
        handlemodalclose={handlemodalClose}
        handleBreakStatusChange={handleBreakStatusChange}
        handlestartbreaksubmit={handlestartbreaksubmit}
        statusbutton={statusbutton}
      />
      <BreakEndModal
        openmodal={modalenddata.open}
        handleendmodalclose={handleendmodalClose}
        handledbuttonsubmit={handledbuttonsubmit}
      />

      {!statusbutton.is_in_break ? (
        <>
          <Button
            sx={{ borderColor: "#C26DBC", color: "#C26DBC" }}
            variant="outlined"
            size="small"
            startIcon={<HourglassBottomIcon />}
            onClick={HandleOpenModel}
          >
            Take a Break
          </Button>
        </>
      ) : (
        <>
          <Button
            sx={{ borderColor: "#C26DBC", color: "#C26DBC" }}
            variant="outlined"
            size="small"
          >
            {statusbutton?.reason}
          </Button>
          <Button
            sx={{ borderColor: "#FF0000", color: "#FF0000" }}
            variant="outlined"
            size="small"
            startIcon={<HourglassDisabledIcon />}
            onClick={HandleOpenEndModel}
          >
            End a Break
          </Button>
        </>
      )}
      <Box display="flex" alignItems="center">
        {statusbutton?.is_in_break === false ? (
          <>
            <CircleIcon
              sx={{
                color: "#00FF00", //green
                fontSize: "20px",
              }}
            />
          </>
        ) : (
          <>
            <CircleIcon
              sx={{
                color: "#FF0000", //red
                fontSize: "20px",
              }}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default BreakStatus;
