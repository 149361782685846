import React, { useEffect, useState } from "react";
import SelectComponent from "../Shared/Select/Select.component";
import { Box, Typography, Modal, Button } from "@mui/material";
import { agentBreakCategory } from "app/utils/api-urls/AgentApiUrls";
import { useQuery } from "@tanstack/react-query";
import { instance } from "app/utils/axiosRequest";
import { breakCategory } from "app/utils/api-urls/AdminAPiUrls";
import { SupervisorbreakButton } from "app/utils/api-urls/SupervisorAPiUrls";
import { API_ENDPOINTS } from "app/utils/api-urls/allUrls";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const BreakModal = ({
  open,
  handlemodalclose,
  handleBreakStatusChange,
  handlestartbreaksubmit,
  required,
  statusbutton,
}) => {
  const userRole = localStorage.getItem("role");
  const [breakCategories, setBreakCategories] = useState();

  const { data: breakCategoryData, error: errorBreakCategory } = useQuery(
    ["role-based-break-categories"],
    () => instance.get(API_ENDPOINTS.BREAK.GET_ALL)
  );
  useEffect(() => {
    breakCategoryData && setBreakCategories(breakCategoryData?.data?.data);
  }, [breakCategoryData]);

  const BreakOptions = breakCategories?.map((item) => ({
    id: item?._id,
    value: item?.name,
    label: item?.name,
  }));
  return (
    <Modal
      keepMounted
      open={open}
      onClose={handlemodalclose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <Typography id="keep-mounted-modal-title" variant="h6">
          Select Break Type
        </Typography>
        <br />
        <Box width="15em">
          <SelectComponent
            options={BreakOptions}
            required={required}
            value={statusbutton?.reason}
            handleChange={handleBreakStatusChange}
            label={"Choose a Break Reason"}
            name={"reason"}
          />
        </Box>
        <br />
        <Box
          display="flex"
          flexDirection="row"
          gap="20px"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Box display="flex">
            <Button onClick={handlestartbreaksubmit} variant="contained">
              Confirm
            </Button>
          </Box>
          <Box display="flex">
            <Button onClick={handlemodalclose} variant="contained">
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default BreakModal;
